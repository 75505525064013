import React from 'react'
import { Link } from 'react-router-dom'

const style = { fontWeight: 550, fontSize: "0.8rem" }

const SimpleLabel = ({ htmlFor = "", label = "", canAdd = false, add = () => { } }) => (
	<label className="mb-0 mr-1" htmlFor={htmlFor} style={style}  >
		<span>{label}</span>
		{canAdd && <span className="ml-2"> (<Link to={ l => l} onClick={() => { add() }}>Agregar</Link>)</span>}
	</label>
)

export default SimpleLabel;