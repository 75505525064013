import React, { useEffect, useState } from 'react';
import templateList from './template';
import { apiFetch, postFile } from '../../services';
import Modal from 'react-modal'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import pdfobject from 'pdfobject'
import Barcode from "react-barcode";
import "./index.css"

Modal.setAppElement("#root")

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    zIndex: "1500"
  },
  content: {
    top: '15%',
    bottom: 'auto',
    margin: 'auto',
    padding: '0px',
    minHeight: "400px",
    maxHeight: "80%"
    //transform: 'translate(-40%, -25%)'
  }
};


const Print = props => {
  //console.log("Estos son props de Print",props)
  const [docurl, setDocUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [maximized, setMaximized] = useState(props.maximize || false);

  const [data, setData] = useState(null);

  const [output, setOutput] = useState('pdf');
    let document = props.document
    const scope = props?.context?.scope?.current
    const [barCodeString, setBarCodeString] = useState('');
    const [barCodeMetaData, setBarCodeMetaData] = useState([]);
  useEffect(() => {
    //carga el documento.
      //console.log("doc changed", props)
      setOutput(props.output);
      if (document) {
          if (props.skip) {
              let datos = {};
              datos['plantilla'] = document.print.plantilla;
              datos['data'] = document.print.data;
              setData(datos)
          } else {
              apiFetch("/exec", {
                  method: "print",
                  d: document
              }, scope).then(result => {
                  if (result?.documento instanceof Array) {
                      setData(result.documento[0])
                  }
              });
          }
      } else {
          setData(null)
      }

  }, [document, scope])

    useEffect(() => {
      
    if (props.data && data == null) {
        let propiedades = {};
        if (typeof props.plantilla !== 'undefined') {
            propiedades = {
                plantilla: props.plantilla,
                version: props.version,
                archivo: props.archivo,
                action: props.action,
                data: (props.data instanceof Array) ? props.data : [props.data]
            }
        } else {
            propiedades = {
                plantilla: props.print.plantilla,
                version: 0,
                archivo: props.print.archivo,
                action: props.print.action,
                data: (props.print.data instanceof Array) ? props.print.data : [props.print.data]
            }
        }
        setData(propiedades)
      props.dispatch({ type: "set-data", path: "print", overwrite: true, payload: null });
    }
  }, [data, props])

  useEffect(() => {
    //prepara el pdf.
    //console.log("print loading", data)
      if (!data) return;

      let handler = templateList[data.plantilla]

    if (handler instanceof Array) handler = handler[data.version || 0];

      if (!(handler instanceof Function)) return;

    let doc = handler(data.data[0]);
    if (!doc) return;

      if (typeof data.data[0] !== 'undefined') {
          let barcodeObj = data.data[0];
          if (barcodeObj.hasOwnProperty("barcode")) {
              setBarCodeString(barcodeObj.barcode);
              setBarCodeMetaData(barcodeObj.metadata);
          }
      }

    const action = props.action || data.action || 'view'

    if (action == "save") doc.save(data.archivo || "documento.pdf")
    else {
      let blob = new Blob([doc.output('blob')], { type: "application/pdf" })
      let url = URL.createObjectURL(blob)
      //console.log("Mis Datos", data.plantilla)
      if(data.plantilla === 'conduce') {
        const cliente_id = data.data[0].cliente.cliente_id;
        const medida_id = data.data[0].id;
        /*const _id = 'PROG-' + cliente_id + '-' + medida_id.toString() + '.pdf';
        postFile('medidas',scope, blob, _id).then(result => {
          const id = result && result.file && Array.isArray(result?.file) ? result.file[0]?.id : result?.file;
          console.log('Mi ID', id)
        }) */ 
        
      }

      if (action == 'print') window.open(url, 'portal_document').print(); //if it's a with-printer device, the print method is not necesary.
      else {
        //showing as modal
        setDocUrl(url); 
        setOpen(true);
      }
    }
    setData(null);
    props.dispatch({ type: "set-data", path: "print", overwrite: true, payload: null });
  }, [data, props.action, props.dispatch]);

  const afterOpenModal = () => {
    //console.log("cargando pdf en modal", docurl)
    pdfobject.embed(docurl, "#pdfdocument", {
      fallbackLink: "<p>Este navegador no soporta la visualización de archivos PDF. Puede descargar el archivo desde <a href='[url]'>este enlace</a>.</p>",
      PDFJS_URL: "/pdfjs/web/viewer.html",
      //forcePDFJS: true,
      pdfOpenParams: { view: 'FitV' }
    })
  }

  const closeModal = () => {
    setOpen(false);
    setDocUrl(null);
  }

    const metalist = (metadata) => {
        return metadata.map((item, i) =>
            <li key={ i } title={item.label}>{item.value}</li>
        );
    }

  return (
    <Modal
      className="col-11 col-sm-9 col-md-8 col-lg-6 col-xl-5 m-auto"
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Documento PDF"}
      shouldCloseOnOverlayClick={true}
    >
      <div className="card rounded-0 m-0">
        <div className="card-header">
          <h5 className="card-title">{"Documento"}</h5>
          <div className="card-tools">
            <button type="button" className="btn btn-tool" data-card-widget="maximize" onClick={() => setMaximized(!maximized)} ><FA icon={maximized ? "compress-alt" : "expand-alt"} /></button>
            <button type="button" className="btn btn-tool" onClick={closeModal} ><FA icon="times" /></button>
          </div>
              </div>
              {
                  (output === 'pdf') ?
                      <div id="pdfdocument" style={{ height: "70vh" }} className="card-body p-0 m-0" /> :
                      <React.Fragment>
                          <div className="card-body p-0 m-0" id="barcode_content">
                              <div className="text-center">
                                  {
                                      (barCodeString.length > 0) ?

                                          <React.Fragment>
                                              <h1>{barCodeString}</h1>
                                              <Barcode value={barCodeString} renderer="img" displayValue={false} width={1} format="CODE128"/>
                                          </React.Fragment>
                                      : <></>
                                  }
                                  <ul className="list-unstyled">
                                    {
                                        (barCodeMetaData.length > 0) ? metalist(barCodeMetaData) : <></>
                                    }
                                  </ul>
                                  <br /><br /><br />
                              </div>
                          </div>
                          <div className="card-footer text-right">
                              <button type="button" className="btn btn-default mr-1" id="btn-print" onClick={window.print}>Imprimir <FA icon="print" /></button>
                              <button type="button" className="btn btn-danger" onClick={closeModal}>Cerrar <FA icon="times-circle" /></button>
                          </div>
                      </React.Fragment>
              }
             
      </div>
    </Modal>
   )
}
export default Print;


