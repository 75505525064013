import { testCard } from "./config.testcard"
import testPage from "./config.testpage"
import testPage2 from "./config.testpage2"

const config = {
  development: {
    "oidcConfig": {
      "authority": "https://auth.dev.grupopropagas.do",
      "client_id": "gp_app",
      "response_type": "code",
      "scope": "openid profile email gp-portal_api",
      "clockSkew": 900
    },
    apiUrl: "https://api.dev.grupopropagas.do",//"https://srvtop19:60004",//
    //testAuthState: {
    //  ready: true,
    //  authenticated: true,
    //  suscription: null,
    //  user: { name: "Test User", scopes: ["admin"] },
    //},
    //testScopes: {
    //  accounts: [
    //    { empresa: "prog", tipo: "empleado", id: "001009" },
    //  ]
    //},
    //testPages: {
    //  menu: [
    //    {
    //      id: "0",
    //      order: "0",
    //      label: "Pruebas Card",
    //      icon: "fas tools",
    //      page: "test/card",
    //      type: "empleado",
    //    }, {
    //      id: "1",
    //      order: "1",
    //      label: "Pruebas",
    //      icon: "fas tools",
    //      page: "testpage",
    //      type: "empleado",
    //    }, {
    //      id: "2",
    //      order: "2",
    //      label: "Segundas Pruebas",
    //      icon: "fas flask",
    //      page: "testpage2",
    //      type: "empleado", 
    //    },
    //  ],
    //  page: [
    //    testCard,
    //    testPage,
    //    testPage2
    //  ]

    //}

  },
  test: {
    "oidcConfig": {
      "authority": "https://auth.dev.grupopropagas.do",
      "client_id": "gp_app",
      "response_type": "code",
      "scope": "openid profile email gp-portal_api",
      "clockSkew": 900
    },
    apiUrl: "https://api.dev.grupopropagas.do"
  },
  release: {
    "oidcConfig": {
      "authority": "https://auth.dev.grupopropagas.do",
      "client_id": "gp_app",
      "response_type": "code",
      "scope": "openid profile email gp-portal_api",
      "clockSkew": 900 //Window of time (in sec) to allow the current time to deviate when validating iat, nbf and exp.
    },
    apiUrl: "https://api.dev.grupopropagas.do"
  }
}

export default config[(process.env.REACT_APP_ENV || process.env.NODE_ENV).toLowerCase()]
