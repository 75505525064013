import { jsPDF } from "jspdf";
/**
 *
 * @param { {
 *   empresa: string,
 *   sucursal: string,
 *   documento: string,
 *   fecha: string,
 *   nombre: string,
 *   cedula: string,
 *   monto: string,
 *   id: string,
 *   usuario: string
 * } props
 *  
 */
function canje(props) {
  const param = props

  const splitTextAndGetHeight = (text, size) => {
    var lines = doc.splitTextToSize(text, size);
    return {
      text: lines,
      height: doc.getTextDimensions(lines).h,
    };
  };

  const options = {
    format: [110, 160]
  };

  var doc = new jsPDF(options);

  var docWidth = doc.internal.pageSize.width;
  var docHeight = doc.internal.pageSize.height;

  var colorBlack = "#000000";
  var colorGray = "#4d4e53";
  //starting at 15mm
  var currentHeight = 15;

  var marginX = 5
  
  //var startPointRectPanel1 = currentHeight + 6;

  var pdfConfig = {
    headerTextSize: 18,
    subHeaderTextSize: 16,
    labelTextSize: 14,
    fieldTextSize: 12,
    lineHeight: 6,
    subLineHeight: 4,
  };

  doc.setFontSize(pdfConfig.headerTextSize);
  doc.setTextColor(colorBlack);

  doc.text(marginX, currentHeight, param.empresa || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  
  doc.setFontSize(pdfConfig.subHeaderTextSize);
  doc.text(marginX, currentHeight, param.sucursal || "");

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.text("Comprobante de Canje", docWidth / 2, currentHeight, { align: "center", lang: "es" })
  
  currentHeight += pdfConfig.subLineHeight;
  
  //line breaker after logo & business info
  //if (param.invoice.header.length) {
  doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
  currentHeight += pdfConfig.subLineHeight;
  //}

  const tableColWidth = [0.3, 0.7]
  const table = [
    ["Fecha:", param.fecha || ""],
    ["Cliente:", param.nombre || ""],
    ["Cédula:", param.cedula || ""],
    ["Monto Canje:", param.monto || ""],
    ["ID:", param.id || ""],
    ["Realizado por:", param.usuario || ""],
  ]

  var tdWidth = tableColWidth.map((rel) => Math.round((doc.getPageWidth() - marginX*2) * rel))

  tdWidth = [marginX +1, ...tdWidth];

  const sumaHasta = (maxIndex) => (agg, current, idx) => agg + (idx <= maxIndex ? current : 0)

  doc.setFontSize(pdfConfig.fieldTextSize);

  //table body
  table.forEach(function (row, index) {

    //get nax height for the current row
    let rowsHeight = [];
    var getRowsHeight = function () {
      row.forEach(function (rr, index) {
        //size should be the same used in other td
        let item = splitTextAndGetHeight(rr.toString(), tdWidth[index+1] - 1); //minus 1, to fix the padding issue between borders
        rowsHeight.push(item.height);
      });
    };
    getRowsHeight();
    var maxHeight = Math.max(...rowsHeight);

    //display text into row cells
    //Object.entries(row).forEach(function(col, index) {
    row.forEach(function (rr, index) {
      let item = splitTextAndGetHeight(rr.toString(), tdWidth[index+1] - 1); //minus 1, to fix the padding issue between borders

      doc.text(item.text, tdWidth.reduce(sumaHasta(index), 0), currentHeight + 4, { lang: "es" });
    });

    currentHeight += maxHeight - 4;

    //td border height
    currentHeight += 6;
  });

  currentHeight += pdfConfig.subLineHeight;
  doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);

  
  return doc;
}

export default canje;
