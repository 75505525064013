import { useCallback, useMemo, useState, useEffect, useRef } from 'react'

const cleanText = text => text && text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

export const useFilterMemo = ({ data = [], struct = [], filtered = {}, name, dispatch }) => {

	//garantiza que sólo se filtre la data cuando se cambie el filtro (ignorando los cambios en el texto)
	const [any, filtro] = useMemo(() => {
		const { any, ...filtro } = filtered;
		return [any, filtro]
	} , [filtered]);

	const [text, setFilter] = useState(any || ""); //contiene el estado del texto en el filtro.
	const lowertext = cleanText(text)
	const filteredAny = useRef(text);

	const setFilterCallback = useCallback(val => setFilter(filteredAny.current = val), [filteredAny, setFilter])

	useEffect(() => {
		return () => { dispatch && ( filteredAny.current || any ) && dispatch({ type: 'set-data', path: `filtered.${name}.any`, payload: filteredAny.current }) }
	}, [any, name, dispatch])

	//todo: corregir bug que no devuelve nada si ninguna columna coincide con struct
	const filteredData = useMemo(() =>
		data
			.filter(row => Object.entries(filtro).every(([key, value]) =>  key==='any' || value === null || value === undefined || row[key] === value))
			.filter(row =>
				lowertext.split(" ").every(key =>
					struct.some(col =>
						row[col.name] &&
						typeof (row[col.name]) !== "object" &&
						cleanText(row[col.name]).includes(key)
					)
				)
		), [lowertext, data, struct, filtro])

	return [filteredData, text, setFilterCallback];
}


