import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useDropzone } from 'react-dropzone'
import { postFile } from '../../../../services'
import SimpleLabel from './simplelabel'

const fileLoader = (state = [], newfile = {}) => {
  const idx = state.findIndex(file => file.name === newfile.name)
  if (newfile.delete || newfile.del) newfile = undefined;

  if (idx == -1) return [...state, newfile].filter(e => !!e);
  state.splice(idx, 1, newfile);
  return [...state].filter(e => !!e)
}

const newFiles = (loadFile, scope, schema, ref) => files => files.forEach(file => { 

  loadFile({
    name: file.name,
    size: file.size,
    loading: true,
  });
  postFile(schema, scope, file, file.name, ref).then(result => {
      const id = result && result.file && Array.isArray(result?.file) ? result.file[0]?.id : result?.file

    loadFile({
      name: file.name,
      size: file.size,
      error: id ? undefined : "No se pudo cargar el archivo",
      id: id,
    })
  }, reason => {
    //console.log("error loading file", file.name, reason);
    loadFile({
      name: file.name,
      size: file.size,
      error: "No se pudo cargar el archivo",
    })
  });
})

const FileInput = ({ name, label, scope, placeholder, path, data, setNewVal, max = 1, schema, accept: contentType }) => {
  const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}${name}` 
  const _data = (data instanceof Array) ? data.filter(e=>!!e) : []
  const [files, loadFile] = useReducer(fileLoader, _data);

  const onDropAccepted = useCallback(newFiles(loadFile, scope, schema, _id), [scope])
  const accept = contentType.replace(";", ",");
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    name,
    accept,
    multiple: max > 1,
    maxFiles: max
  })
  
  const newdata = files.filter(file => !!file.id).map(({ name, id }) => ({ id, name }))

  const oldids = _data.map(file => file.id).join(',')
  const newids = newdata.map(file => file.id).join(',')

  useEffect(() => {
    if (oldids != newids) setNewVal(newdata);
  }, [oldids, newids, newdata, setNewVal])

  const fileList = files.map(({ name, error, id, loading }) => (
    <li key={name} style={{ borderRadius: "10px", display: "inline-block", backgroundColor: "hsl(0,0%,90%)" }} className="mr-1 mb-1 px-1" >
      {error && <FontAwesomeIcon className="mr-1" icon={["fas", "exclamation-circle"]} color="#bf4040" title={error} />}
      {id && <FontAwesomeIcon className="mr-1" icon={["fas", "check-circle"]} color="green" title={id} />}
      {loading && <FontAwesomeIcon className="mr-1" icon={["fas", "circle-notch"]} spin title={"Cargando..."} />}
      <span>{name}</span>
      <span style={{ cursor: "pointer" }} onClick={() => loadFile({ name, delete: true })} > <FontAwesomeIcon className="ml-1" icon={["far", "times-circle"]} title={"Eliminar"} /></span>
    </li>
  ));

  return (
    <React.Fragment>
      <SimpleLabel label={label} htmlFor={_id} />
      {
        files.length < max
          ? <div {...getRootProps({
            id: _id,
            style: {
              border: "#ced4da dashed 2px",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              borderRadius: "2px",
              padding: "8px",
              alignItems: "center",
              backgroundColor: "#fafafa",
              color: "#bdbdbd"
            }
          })}>
            <input {...getInputProps({ className: "form-control form-control-sm text-weight-normal" })} />
            {
              isDragActive
                ? <p className="my-1" >Suelte los archivos aquí...</p>
                : <p className="my-1" >Arrastre archivos aquí o haga clic para seleccionar.</p>
            }
            {placeholder && <p className="mt-0 mb-1 font-italic" >{placeholder}</p>}
          </div>
          : null
      }
      <aside>
        <ul id={_id + "list"} className="p-0 mt-1">
          {fileList}
        </ul>
      </aside>

    </React.Fragment>
  )
}


export default FileInput;
