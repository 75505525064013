import React from 'react';
import { exportToExcel } from './excelexport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableSubHeader = ({ data, struct, filter, onFilter, name, ...props }) => {
	
	return (
		<React.Fragment>
			<div className="input-group" style={{ width: "250px" }} >
				<input id={`board${name}filter`}
					className="form-control form-control-sm text-weight-normal"
					type="text"
					name={`board${name}filter`}
					placeholder="Filtrar..."
					value={filter}
					onChange={(e) => onFilter(e.target.value)}
				/>
				<div className="input-group-append">
					<span className="input-group-text" onClick={() => onFilter("")}>
						<FontAwesomeIcon icon={["fas", filter.length ? "search-minus" : "search"]} />
					</span>
				</div>
			</div>
			<button
				type="button"
				className="btn btn-outline-secondary btn-sm ml-1 my-1"
				onClick={() => exportToExcel({ data: [{ data }], struct, ...props })}
			>
				<FontAwesomeIcon icon={["fas", "file-excel"]} /> Descargar
			</button>
		</React.Fragment>
	)
}

export default TableSubHeader;

