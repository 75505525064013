import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useColumnsMemo } from './parsecolumns';
import TableSubHeader from './subheader';
import { useFilterMemo } from './usefilter';
import config from './config';
import { resolveCondition } from '../../condition';
import { useReducer } from 'react';
import { asBool } from '../../form/input/utils';

const solverfn = (local) => (condition, style) => {
	const result = resolveCondition(condition, { local });
	const ishidden = (result === "hide" || !result) ? { style: { display: "none", ...style } } : { style }
	const isdisabled = result === "disable" ? { disabled: true } : {}
	return { ...ishidden, ...isdisabled }
}


const TableElement = ({ data, title, hasClickActions, selectable, hasButtons, click, buttons: ActionList, ...props }) => {
	const columns = useColumnsMemo(props);
	const [filteredData, filterText, setFilter] = useFilterMemo({ data, ...props });
	const [selected, setSelected] = useState({ selectedRows: [] });
	const buttonShouldDisplay = useMemo(() => solverfn({ selected: selected.selectedRows }), [selected])
	const [clearState, clear] = useReducer( state => !state, true )
	
	return (
		<React.Fragment>
			<div className="card-body p-0">
				<DataTable
					title={title}
					columns={columns}
					data={filteredData}
					actions={<TableSubHeader data={filteredData} title={title} filter={filterText} onFilter={setFilter} {...props} />}
					subHeader={false}
					defaultSortField={undefined}
					pointerOnHover={hasClickActions}
					onRowClicked={row => click(row)()}
					selectableRows={hasButtons && asBool(selectable)}
					clearSelectedRows={clearState}
					expandableRows={false}
					noDataComponent={<p className="m-3" >Sin registros disponibles</p>}
					progressPending={false}
					onSelectedRowsChange={setSelected}
					{...config}
					style={({overflowX: "none"})}
				/>
			</div>
			<div className="card-footer">
				<div className="form-row justify-content-md-end">
					<ActionList data={({ selected: selected.selectedRows })} close={clear} isDisabled={props.isDisabled} solver={buttonShouldDisplay}  processingFlagPath={props.processingFlagPath} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default TableElement;

