import React from 'react'
import { useMemo } from 'react'
import { resolveExpression } from '../page/utils'
import ReactJson from 'react-json-view'
import { getFormatted } from './formatcomponent'

const rjvOptions = {
  iconStyle: "triangle",
  indentWidth: 2,
  collapsed: 1,
  collapseStringsAfterLength: 20,
  enableClipboard: false,
  displayObjectSize: false,
  displayDataTypes: false,
  quotesOnKeys: false
}

const CacheComponent = ({ node: { children: [node] }, title, format, currency, localData, pageData }) => {
  const path = node?.value || ""

  const content = useMemo(() => path && resolveExpression(`"@${path}"`, { global: pageData, local: localData }), [path, pageData, localData])

  return (content instanceof Object)
    ? <ReactJson src={content} {...rjvOptions} />
    : <span title={title} >{getFormatted(content, format, currency)}</span>
}

export default CacheComponent