import React, { useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { getObjectValue, getEntityFromSample, resolveExpression, isValid } from "../utils";

import Selector from './selector';

// const Loading = () => (
//   <div className="card-body">
//     <div className="col overlay mt-5 text-center " style={{ minHeight: "150px" }}>
//       <FontAwesomeIcon icon="sync-alt" size="2x" spin />
//     </div>
//   </div>
// )

const Board = ({ path, data, localPath, localData, refreshing, entity, hidden = false, hideifempty = false, ...rest }) => {
  const datapath = JSON.stringify(isValid(localPath) ? `@local${(localPath && '.') || ''}${localPath || ''}` : `@global.${path}`)
  const newData = useMemo(() => resolveExpression(datapath, { global: data, local: localData }), [data, localData])
  const [currentData, setCurrentData] = useState(newData) //for preventing unnecesary refresh.

  const struct = useMemo(() => {
    if (entity) return entity.filter( e => e?.type !== "section" )

    const inner = currentData && ((currentData instanceof Array) ? currentData : [currentData])
    if (!inner) return undefined
    //console.log("inner", Object.keys(inner[0]));

    return getEntityFromSample(inner[0] || { Col1: "No Rows" });
  }, [entity, currentData])

  useEffect(() => {
    if (!isEqual(currentData, newData)) {
      setCurrentData(newData)
    }
  }, [currentData, newData])

  if (hidden && !rest.fromForm) return null
  if (hideifempty && !currentData?.length) return null;
  //console.log("board:", rest.name, "currentData:", currentData);

  if (rest.fromForm) return <Selector data={currentData || []} outerPath={path} struct={struct} pageData={data}  {...rest} />
  else return (
    <div className={`card card-outline rounded-0 ${refreshing ? "card-secondary" : "card-info"}`}>
      <Selector data={currentData || []} outerPath={path} struct={struct} pageData={data} {...rest} />
    </div>
  )
}


export default Board
