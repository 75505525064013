import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as isEqual from 'react-fast-compare';
import ListField from './listfield';
import { resolveCondition } from '../../condition';
import { asBool } from '../input/utils';


const ListData = ({ struct, idx, path, elm, min, childCount, notremovable, removeItem, dispatch, itemPreview, parent, noheader = false, ...props }) => {
	const id = `itemlist${(props.form_path || "").replace(/([._-]+)/g, '')}${(path || "").replace(/([._-]+)/g, '')}${idx}`
	const [sectionMode, setSectionMode] = useState({});
	const removable = asBool(notremovable) ? false : (isNaN(min) ? true : childCount > Number(min) )

	const itemClass = `list-group-item py-1 pl-2 ${removable ? "pr-1" : "pr-2"} bg-transparent ${path ? "border-bottom rounded-bottom" : "border-0"}`
	const dragClass = `drag-handle-${(path || "").replace(/([._-]+)/g, '')} mr-2`
	const shouldshow = !path || noheader || isEqual(elm, {}) ? "show" : "";
	const [show] = useState(shouldshow)

	if (!elm) return null;
	let hiddenSection = false;
	let disabledSection = {};
	let isreadmode = false;

	//style={({ backgroundColor:"rgba(226, 233, 233, 0.8)" })}
	return (
		<li key={idx} className={itemClass}>
			<div className="card bg-transparent m-0" style={({ boxShadow: "none"})} >
				{
					path && !noheader && 
					<div className="card-header p-0 pr-3 border-0" >
						{
							childCount > 1 &&
							<span className={dragClass} style={{ cursor: "move" }} >
								<FontAwesomeIcon icon={["fas", "grip-lines"]} />
							</span>
						}
						<a href={`#${id}`} className="text-dark " data-toggle="collapse" >{`${idx + 1}: ${itemPreview(elm)}`}</a>
						<div className="card-tools">
							{removable && <button type="button" className="btn btn-tool text-red" onClick={() => removeItem(idx)} ><FontAwesomeIcon icon={["far", "trash-alt"]} /></button>}
						</div>
					</div>
				}
				<div id={id} className={`collapse ${show}`}>
					<div className="card-body p-1">
						<div className="form-row">
							{
								struct.map(field => {
									const newpath = (path ? path + "." + idx.toString() + "." : "") + (field.path || field.name)
									
									const conditionResult = resolveCondition(field.condition, { global: props.pageData, local: { parent, ...elm } })
									const ishidden = (conditionResult === "hide" || !conditionResult) ? true : false
									const isdisabled = conditionResult === "disable" ? { readonly: true } : {}
									
									const issection = field.type === "section"

									if (issection) { //save ishidden && isreadmode state for entire section.
										hiddenSection = ishidden
										disabledSection = isdisabled
										isreadmode = !!field.modes && field.modes != 'edit' && (sectionMode[field.name] || (sectionMode[field.name] != false && (field.initmode || 'read') == 'read'))
									}

									const toggleReadMode = issection && field.modes == 'both' ? (mode) => setSectionMode({ ...sectionMode, [field.name]: mode }) : null

									if (ishidden || hiddenSection || (isreadmode && !issection)) return null;
									else return <ListField key={newpath} rowIndex={idx} path={newpath} field={({ ...field, ...isdisabled, ...disabledSection })} dispatch={dispatch} toggleReadMode={toggleReadMode} isreadmode={ isreadmode } fieldData={({ parent, ...elm })} {...props} />
								})
							}
						</div>
					</div>
				</div>

			</div>
		</li>
	)
}

export default ListData;

