import React, { useEffect, useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useDebouncer } from './usedebouncer';
import './signature.css';

const requiredValidation = "Completa este campo";

const SignaturePad = ({
  name,
  label,
  value,
  path,
  data,
  setNewVal,
  readonly,
  required,
}) => {
  const _id = `input${path.replace(/(\.\.)|([._-]+)/g, "")}${name}`;
  const current = data !== undefined ? data : value;
  const [url, setUrl] = useDebouncer(current, setNewVal);
  const notSetted = !url && url !== "";

  const signatureRef = useRef(null);
  const containerRef = useRef(null);
  
  // Estado para controlar la visibilidad del SignaturePad
  const [isSignaturePadVisible, setSignaturePadVisible] = useState(true);
  const [isConfirmDisabled, setConfirmDisabled] = useState(false);

  const handleClear = () => {
    if (signatureRef.current) {
      signatureRef.current.clear(); // Limpia el lienzo solo si signatureRef.current no es null
    }
    setUrl(""); // Limpiamos el URL de la firma
    setConfirmDisabled(false); // Habilitar el botón Confirmar de nuevo
    setSignaturePadVisible(true); // Volver a mostrar el SignaturePad si se ocultó
  };

  const handleGenerate = () => {
    if (signatureRef.current) {
      const imageUrl = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
      setUrl(imageUrl); // Guardamos la imagen en formato Base64
      setConfirmDisabled(true); // Deshabilitar el botón Confirmar después de hacer clic
      setSignaturePadVisible(false); // Ocultar el SignaturePad
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const adjustCanvasSize = () => {
    if (containerRef.current && signatureRef.current) {
      const { offsetWidth } = containerRef.current;
      signatureRef.current.getCanvas().width = offsetWidth;
      signatureRef.current.clear();
      if (url) {
        signatureRef.current.fromDataURL(url);
      }
    }
  };

  const debouncedAdjustCanvasSize = debounce(adjustCanvasSize, 300);

  useEffect(() => {
    adjustCanvasSize();

    window.addEventListener('resize', debouncedAdjustCanvasSize);
    return () => window.removeEventListener('resize', debouncedAdjustCanvasSize);
  }, [url]);

  const inputRef = useRef(null);

  const props = {
    onChange: required
      ? (event) => {
          if (notSetted && required) {
            event.target.setCustomValidity(requiredValidation);
          } else {
            event.target.setCustomValidity("");
          }
          setUrl(event.target.value || undefined);
        }
      : (event) => setUrl(event.target.value || undefined),
    onClick: () => {
      if (inputRef.current) inputRef.current.setCustomValidity(""); // Limpiar la validez personalizada
    },
  };

  return (
    <React.Fragment>
      <div className="signature-container" ref={containerRef}>
        <label htmlFor={_id} className="signature-label">{label}</label>

        {/* Mostrar el SignaturePad solo si isSignaturePadVisible es true */}
        {isSignaturePadVisible ? (
          <SignatureCanvas
            id={_id}
            ref={signatureRef}
            penColor="blue"
            backgroundColor="rgba(255, 255, 255, 0)"
            canvasProps={{
              className: "SigCanvas",
              height: 200,
              style: { border: '2px solid black', borderRadius: '8px' },
            }}
            clearOnResize={false}
          />
        ) : (
          <div>
            <h3>Firma Confirmada:</h3>
            <img src={url} alt="Firma" />
          </div>
        )}

        <div className="button-container">
          <button onClick={handleClear} className="btn btn-outline-secondary">
            Limpiar
          </button>
          <button
            onClick={handleGenerate}
            className="btn btn-outline-primary"
            disabled={isConfirmDisabled} // Deshabilitar el botón según el estado
          >
            Confirmar
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignaturePad;
