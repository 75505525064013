import React from 'react'
import { firstValid, getObjectValue } from '../../utils';
import { asBool } from '../input/utils';
import ListData from './listdata';

const List = ({ struct = [], data: formData, dispatch, path, ...props }) => {
	const data = firstValid(formData, []);
	
	const listData = ((data instanceof Array) ? data : [data])
	if (!path && listData.length > 1) throw Error("Se espera sólo un elmento en [data] o un [path]");

	const childCount = listData.filter(e => e).length

	const itemPreview = ((struct = []) => {
		let filtered = struct.filter(e => asBool(e.preview));
		if (!filtered.length) filtered = struct.filter(e => !e.hideonform && e.type !== "list").slice(0, 3); //first three
		//const selected = filtered.map(e => { path: (e.path || e.name), formatter: e.formatter })
		return item => filtered.map(field => field.formatter(item.currency).format(getObjectValue(item, (field.path || field.name)))).join(" - ");
	})(struct)

	const removeItem = (idx) => {
		const newdata = [...listData];
		newdata.splice(idx, 1)
		dispatch({ type: "set-value", path: path, payload: newdata })
	}

	return listData.map((elm, idx) => elm && <ListData key={idx} idx={idx} elm={elm} childCount={childCount} struct={struct} path={path} removeItem={removeItem} itemPreview={itemPreview} dispatch={dispatch} {...props} />)
}

export default List;
