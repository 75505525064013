import React, { useMemo } from 'react'
import { h } from 'hastscript';
import { map } from 'unist-util-map';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive'
import BoardComponent from './boardcomponent';
import LinkComponent from './linkcomponent';
import PComponet from './pcomponent';
import IconComponent from './iconcomponent';
import CacheComponent from './cachecomponent';
import ButtonComponent from './buttoncomponent';
import FormatComponent from './formatcomponent';
import ImageComponent from './imagecomponent';

const isDirectiveNode = (node) => {
  const { type } = node;
  return type === 'textDirective' || type === 'leafDirective' || type === 'containerDirective';
};

const mapDirectiveNode = (node) => {
  if (isDirectiveNode(node)) {
    const { properties, tagName } = h(node.name, node.attributes);
    return Object.assign(Object.assign({}, node), {
      data: {
        hName: tagName,
        hProperties: properties
      }
    });
  }
  return node;
};
const transformNodeTree = (nodeTree) => map(nodeTree, mapDirectiveNode);
const remarkDirectiveRehype = () => transformNodeTree;

const nodeFinder = node => ({ ...node, find: tag => nodeFinder(node.children.find(val => val.tagName == tag || (val.type == "text" && val.value == tag))) })
const base12width = width => width >= 12 ? 100 : width * (99/12)

const TableComponent = ({ children, node }) => {

  const headers = nodeFinder(node).find('thead').find("tr").children.filter(n => n.tagName === "th").map(n => Number((n.children[0]?.tagName == "width" && n.children[0]?.children[0]?.value) || -1))
  const nowidth = !headers.find(e => e != -1)
  const width = headers.find(e => e == -1) ? (nowidth ? undefined : 100) : headers.reduce((a, b) => a + (b > 0 ? b : 0), 0)

  return <table style={{ width: isNaN(width) ? undefined : `${base12width(width)}%` }}>{children}</table>
}

const TableHeaderComponent = ({ children, node, style }) => {
  const width = Number((node.children[0]?.tagName == "width" && node.children[0]?.children[0]?.value) || undefined)
  
  return <th style={{ width: isNaN(width) ? undefined : `${base12width(width)}%`, ...style }}>{children}</th>
}


const definedComponents = {
  a: LinkComponent,
  fa: IconComponent,
  icon: IconComponent,
  p: PComponet,
  board: BoardComponent,
  tablero: BoardComponent,
  cache: CacheComponent,
  format: FormatComponent,
  align: () => null,
  size: () => null,
  width: () => null,
  button: ButtonComponent,
  table: TableComponent,
  th: TableHeaderComponent,
  img: ImageComponent,
  td: ({ children, style }) => <td className="px-1" style={{ ...style }}>{children}</td>,
  tr: ({ children, style }) => <tr className="py-1" style={{...style}} >{children}</tr>

}
const baseMDElements = ['a', 'blockquote', 'br', 'code', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'li', 'ol', 'p', 'pre', 'strong', 'ul', 'del', 'table', 'tbody', 'td', 'th', 'thead', 'tr']
const allowedElements = [...baseMDElements, ...(Object.keys(definedComponents))]

const componentWrapper = (components, outerprops) => Object.entries(components).reduce(componentWrapperReducer(outerprops), {})
const componentWrapperReducer = outerprops => (obj, [tag, Component]) => ({ ...obj, [tag]: (props) => <Component {...outerprops} {...props} /> })

const MarkDown = ({ children, ...outerprops }) => {
  const components = useMemo(() => componentWrapper(definedComponents, outerprops), [outerprops])


  if (!children) return null

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkDirective, remarkDirectiveRehype]}
      allowedElements={allowedElements}
      components={components}
    >
      {children}
    </ReactMarkdown>)
}


export default MarkDown;
