import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'

const FrontDoor = ({ message, button, link, detail }) => {

	useLayoutEffect(() => {
		const oldbg = document.body.style.backgroundColor
		document.body.style.backgroundColor = "#1b3f78";
		return () => document.body.style.backgroundColor = oldbg
	}, [])


	return (
		<div>
			<div className="card mx-auto my-5" style={{ width: "20rem" }}>
				<img alt="logo" width="75%" className="mx-auto" src="/logo-grupo-completo.png"></img>
				<div className="card-body">
					<h5 className="text-center">{message}</h5>
					{detail ? <p className="card-text small text-center">{detail}</p> : null }
					{button ? <p className="card-text mt-4"><Link to={link} className="btn btn-default d-block">{button}</Link></p> : null}
				</div>
			</div>
		</div>
	)
}

export default FrontDoor