
export default {
	selectableRowsHighlight: true,
	striped: false,
	//pointerOnHover: false,
	highlightOnHover: true,
	dense: true,
	persistTableHead: true,
	fixedHeader: true,
	fixedHeaderScrollHeight: "50vh",
	pagination: true,
	contextMessage: {
		singular: "registro seleccionado",
		plural: "registros seleccionados"
	},
	paginationRowsPerPageOptions: [10, 25, 50, 100],
	paginationComponentOptions: {
		rowsPerPageText: "Mostrar",
		rangeSeparatorText: "de",
		noRowsPerPage: false,
		selectAllRowsItem: false,
		selectAllRowsItemText: "Todas"
	},
	customStyles: {
		tableWrapper: {
			style: {
				display: 'block',
				overflowX: 'auto'
			},
		},
		table: {
			style: {
				display: 'table',
				overflowX: 'auto'
			},
		},
		rows: {
			style: {
				paddingLeft: '8px',
				paddingRight: '8px',
			}
		},
		headRow: {
			style: {
				overflowY: 'scroll',
				paddingLeft: '8px',
				paddingRight: '8px',
			}
		},
		headCells: {
			style: {
				paddingLeft: '8px',
				paddingRight: '2px',
			}
		},
		cells: {
			style: {
				paddingLeft: '8px', // override the cell padding for data cells
				paddingRight: '2px',
			},
		},
	}
}

