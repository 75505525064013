import React from "react";
import { Link } from "react-router-dom";

const listFilter = (list, filter = []) => {
  return list.filter(item => !filter || filter.every((e) => !e[0] || item[e[0]] === e[1]))
}

const LinkText = ({ value }) => (
  <li className="nav-item">
    <Link to={l => l} className="nav-link disabled text-dark">{value}</Link>
  </li>
)

const LinkDropDown = ({ labels, options, value, idx, setAccount }) => (
  <li className="nav-item dropdown">
    <Link to={l => l} className="nav-link dropdown-toggle text-dark px-2" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
      {value}
    </Link>
    <div className="dropdown-menu dropdown-menu-right" style={{ maxHeight: "80vh", maxWidth: "80vw", overflow: "auto" }}>
      {options.map((item, i) => (<Link to={l => l} onClick={(e) => { setAccount(idx, item, e); }} className="dropdown-item" key={i}>{labels[i] || item}</Link>))}
    </div>
  </li>
)


const TextOrCombo = ({ list = [], current, idx, ordered, setAccount }) => {
  const values = [], idlabels = []
  const filter = ordered.slice(0, idx)
  const _list = listFilter(list, filter.map(e => [e, current[e]]))
  
  _list.forEach((item) => { 
    if (!values.includes(item[ordered[idx]])) {
      values.push(item[ordered[idx]]) 
      if (idx === 2) idlabels.push(item.label)
    }
    })

  return (values.length > 1
    ? <LinkDropDown labels={idlabels} options={values} value={current[ordered[idx]]} idx={idx} setAccount={setAccount} />
    : <LinkText value={current[ordered[idx]]} />
  )
}

const ScopeSelector = ({ scope, dispatch }) => {
  if (!scope) return null

  const ordered = ["empresa", "tipo", "id"]

  const setAccount = (idx, val) => {
    if (val === scope.current[ordered[idx]]) return
    var filter = ordered.slice(0, idx)
    const name = ordered[idx]

    filter = listFilter(scope.accounts, filter.map(e => [e, scope.current[e]]))

    dispatch({ type: "set-account", payload: filter.find((e) => e[name] === val) })
    if (name === "tipo") dispatch({ type: "set", payload: { redirect: "/" } })
  }

  const props = {
    list: scope.accounts,
    current: scope.current,
    ordered,
    setAccount
  }

  return (scope.accounts
    ? (
      <ol className="nav text-dark" >
        <div className="ml-auto"><TextOrCombo {...props} idx={0} /></div>

        <li className="nav-item"><Link to={l => l} className="nav-link disabled px-0 mx-0">/</Link></li>
        <TextOrCombo {...props} idx={1} />
        <li className="nav-item"><Link to={l => l} className="nav-link disabled px-0 mx-0">/</Link></li>
        <TextOrCombo {...props} idx={2} />
      </ol>
    )
    : null
  )
}

export default ScopeSelector;

export { TextOrCombo, listFilter };