
import React, { useMemo } from "react";
import { asArray, firstValid, getObjectValue, getTriggerAction, isValid } from "../../utils";
import { getCellValue } from "../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const adjustright = ["number", "date", "time", "datetime-local"]

const colTyped = (col, data) => {
  let value = getCellValue(data, col.path || col.name || "");

  switch (col.type) {
    case "number": return col.decimal ? parseFloat(value) : parseInt(value)
    default: return value
  }
}

const asTextArray = (textArray, delimiter = ";") => typeof textArray === "string" ? textArray.split(delimiter) : (Array.isArray(textArray) ? textArray : undefined)

export const colGrow = size => (
  (size === 'xxs' && 0.2) ||
  (size === 'xs' && 0.5) ||
  (size === 'sm' && 0.8) ||
  (size === 'md' && 1.0) ||
  (size === 'lg' && 1.75) ||
  (size === 'xl' && 3.0) ||
  (size === 'xxl' && 5.0) || 1
)

export const buttonCell = (meta, col) => (row, index, defaultbuttons) => {
  const cells = asArray(firstValid(getObjectValue(row, col.path || col.name || ""), defaultbuttons, []))

  const clickHandler = cell => cell.action || col.action ? getTriggerAction({ actionNames: cell.action || col.action, meta, withData: col.data })({ ...row, _index: index }) : null
  const className = ["btn", "btn-sm", "p-0", "mr-1", "btn-outline-default", ...(asTextArray(col.style) || [])];

  const buttonmap = (cell, i) => (
    <div key={i} className="d-inline">
      <button type="button" title={cell.placeholder || col.placeholder} onClick={clickHandler(cell)} className={[...className, ...(asTextArray(cell.style) || [])].join(" ")} style={{ cursor: (cell.action || col.action) ? "pointer" : "default" }} >
        {cell.icon && <FontAwesomeIcon className="mx-1" icon={cell.icon?.split(" ")} />}
        {cell.label && <span className="mx-1">{cell.label}</span>}
      </button>
    </div>
  )

  return cells.map((cell, i) => (
    (cell.label || cell.icon || typeof cell === "string")
      ? buttonmap(typeof cell === "string" ? { label: cell } : cell, i)
      : null
  ))
}

const colFormatter = (col) =>
  (col.formatter) ? data => {
    const value = getCellValue(data, col.path || col.name || "")
    if (col.type === "number")
      return isNaN(value) ? undefined : col.formatter(data.currency, value).format(value)
    else
      return col.formatter(undefined, value).format(value);
  }
    : undefined


export function useColumnsMemo({ struct = [], meta }) {
  const columns = useMemo(() => {
    return struct.map(col => {
      return {
        name: col.title || col.label || col.name,
        selector: data => colTyped(col, data),
        format: colFormatter(col),
        sortable: col.type !== "auto",
        right: adjustright.some(coltype => col.type === coltype) ? true : false,
        center: false,
        omit: col.hideonboard,
        minWidth: `${100 * colGrow(col.size)}px`,
        grow: colGrow(col.size),
        cell: col.type == "button" ? buttonCell(meta, col) : null
      }
    })
  }, [struct])


  return columns;
}


