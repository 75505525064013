import React from 'react'
import { displayFormatter } from '../page/utils'

const pickFmt = type => {
  switch (type) {
    case "number":
    case "date":
    case "time":
    case "datetime-local":
    case "text": return { type }
    case "percent": return { type: "number", percent: true }
    case "percentdecimal": return { type: "number", percent: true, decimal: true }
    case "decimal": return { type: "number", decimal: true }
    case "datetime": return { type: "datetime-local" }
    case "integer": return { type: "text" }
    case "money": return { type: "number", decimal: true }
    default: return { type: "custom" }
  }
}

export const getFormatted = (value, format, currency) => displayFormatter({ ...pickFmt(format), currency })(currency, value).format(value)

const FormatComponent = ({ node: { children: [node] }, title, type, currency }) => {
  const value = node?.value || ""

  if (value === "") type = "text"

  return <span title={title} >{getFormatted(value, type, currency)}</span>
}

export default FormatComponent