
import React from 'react'
import ActionList from './actionlist'
import queryString from 'query-string'
import { asBool } from './form/input/utils'
import { computeThis } from './form/list/usecomputed'

const solve = (obj = {}, path = [], i = 0) => {
  return obj && (path.length <= (i + 1) ? (path.length === i ? obj : obj[path[i]]) : solve(obj[path[i]], path, i + 1))
}

const replacer = (container) => (match, ini, objname, path, fin) => {

  if (path) path = resolveExpression(JSON.stringify(path), container)

  var json = path ? solve(container[objname], path.split(".")) : container[objname]
  json = json === undefined ? null : json
  //console.log("solving", match, "to", json, ini, fin)
  if (ini && fin) return JSON.stringify(json);
  else return String.prototype.concat(ini || '', JSON.stringify(json?.toString() || '').slice(1, -1), fin || '')
}

export const resolveExpression = (exp, obj) => {
  //console.log("resolving expression:", exp, obj)

  if (!exp) return exp;

  //const regex = new RegExp(/(?:("{0,1})@(local|global)(?!\.[^;"\s]*@))(?:(?:\.([\w\dáéíóúñ.]+)*)|(?=[\s}\],"]|$))(?:[;]|("{0,1}))/gi);
  //const regex = new RegExp(/(?:("{0,1})@(local|global))(?:(?:\.((?:[\w\dáéíóúñ.]+|@[\w\dáéíóúñ.]+;\.)+)*)|(?=[\s}\];"]|$))(?:[;]|("{0,1}))/gi);
  const regex = new RegExp(/(?:("{0,1})@(local|global|cookie))(?:(?:\.((?:[\w\dáéíóúñ.]+|@[\w\dáéíóúñ.]+;[.]{0,1})+)*)|(?=[\s}\];"]|$))(?:[;]|("{0,1}))/gi);
  //const container = obj

  while (regex.test(exp)) {
    exp = exp.replace(regex, replacer(obj))
    break;
  }

  return JSON.parse(exp);
}

export const getObjectValue = (obj = {}, path = "", i = 0) => {
  if (typeof path === "string") path = (path ? path.split(".") : [])
  return solve(obj, path, i)
}

export const updateObjectValue = (obj, path, val, overwrite = false, autocreate = true, i = 0) => {

  if (typeof path === "string") path = (path ? path.split(".") : [])
  else if (!(path instanceof Array)) {
    console.warn("Una acción intenta modificar un objeto sin ruta (path) especificada");
    return;
  }

  if (path.length <= i + 1) { //si path === []: 
    const oldVal = (path.length === i ? obj : obj[path[i]]);

    if (path[i] === "push()") {
      if (obj instanceof Array) obj.push(val);
      else console.warn("Una acción intenta añadir(push) un valor en un objeto");
    }
    else if (oldVal instanceof Array || !(oldVal instanceof Object) || !(val instanceof Object)) {
      if (val === undefined || val === null) delete obj[path[i]]
      else obj[path[i]] = JSON.parse(JSON.stringify(val))
    } else if (overwrite)
      obj[path[i]] = (val === undefined) ? undefined : JSON.parse(JSON.stringify(val));
    else
      Object.entries(val).forEach(([key, value]) => { oldVal[key] = (value === undefined) ? undefined : JSON.parse(JSON.stringify(value)) })

    return;
  }

  if (obj[path[i]] === undefined || obj[path[i]] === null) {
    if (autocreate) obj[path[i]] = ((isNaN(path[i + 1]) && path[i + 1] !== "push()") ? {} : [])
    else return; //should not autocreate since object dont exists.
  }

  updateObjectValue(obj[path[i]], path, val, overwrite, autocreate, i + 1)
}


export const getActionListOuterData = (action, data, neededData = {}) => {
  const newNeeded = {}
  let shouldUpdate = false;

  if (action)
    action.forEach((act) => {
      if (act.property)
        act.property.forEach((prop) => {
          if (prop.source === "abs") {
            newNeeded[prop.value] = newNeeded[prop.value] || getObjectValue(data, prop.value)
            if (neededData[prop.value] !== newNeeded[prop.value]) shouldUpdate = true;
          }
        });
    });

  return [newNeeded, shouldUpdate];
}


export const syncQueryString = ({ dispatch, meta, query, outerData }) => {
  if (!meta) return;

  let search = meta.location.search || "?";
  const searchObj = search && queryString.parse(search);
  const dataQuery = query;
  const dataObj = {};
  const current = meta.location.current;
  let data;

  if (dataQuery instanceof Object) { //query expected
    Object.entries(dataQuery).forEach(([key, detail]) => {
      dataObj[key] = outerData[detail];

      if (search !== current && dataObj[key] !== (searchObj && searchObj[key])) {

        //console.log('updating data from search', detail, searchObj && searchObj[key], "search: ", search, "current:", current)
        dispatch({ type: "set-data", path: detail, payload: searchObj && searchObj[key] })
      }
    })

    data = "?" + queryString.stringify(dataObj, { skipEmptyString: true, skipNull: true });
    search = "?" + queryString.stringify(searchObj, { skipEmptyString: true, skipNull: true })

    if ((!current && search) || (current !== search)) {
      //console.log("setting current from search:", search)
      dispatch({ type: "set-data", path: "meta.location.current", payload: search });
    } else if (data !== current) {
      //console.log("pushing this", data, "from current: ", current)
      meta.history.push(`${meta.location.path}${data}`);
    }


  }

  //console.log("current location: ", `>${search}<`, "|" , current, "|", data);
}

//retorna una función que recibe (data, close) y que a su vez retorna una función que ejecutará las acciones concretas.
export const getTriggerAction = ({ actionNames = [], meta, withData = {} }) => {
  const actions = (actionNames instanceof Array) ? actionNames : actionNames.split(";").filter(e => e);

  return actions.length
    ? (data, close, processingFlagPath) => () => meta.run(actions, { ...withData, ...data, }, close, processingFlagPath)
    : () => async () => { }
}

const getButtons = (buttonList = [], properties = {}) => {
  //console.log("rendering this buttonlist:", JSON.stringify(buttonList));

  const buttons = buttonList.map(button => {
    var withData = tryJSON(button.data) || {}
    if (!(withData instanceof Object)) {
      console.warn(`Intentó recibir data en ${button.label} pero recibió ${typeof (withData)}: ${withData}`);
      withData = {}
    }
    const flags = {};

    if (button.flags) (button.flags?.split(";") || []).forEach(flag => flags[flag] = true);

    return {
      ...button,
      ...flags,
      trigger: getTriggerAction({ actionNames: button.action, withData, ...properties })
    }
  })

  return parentProps => <ActionList buttons={buttons} {...parentProps} />
}

export const typeFromSample = sample => {
  let customtype;
  let decimal;

  if (isNaN(sample)) {
    const dateortimeregx = new RegExp(/^(\d{2,4}[-/]\d{2}[-/]\d{2}){0,1}[\sT]{0,1}((\d{1,2}:{0,1}){3}(\.\d{1,6}){0,1}){0,1}$/)
    if (dateortimeregx.test(sample)) {
      const datetimeregx = new RegExp(/^(\d{2,4}[-/]\d{2}[-/]\d{2})[\sT](\d{1,2}:{0,1}){3}(\.\d{1,6}){0,1}$/)
      const timeregx = new RegExp(/^([0-9]{1,2}:{0,1}){3}(\.\d{1,6}){0,1}$/)

      if (datetimeregx.test(sample)) customtype = "datetime-local";
      else if (timeregx.test(sample)) customtype = "time";
      else customtype = "date";
    } else customtype = "text";

  } else {
    const intregx = new RegExp(/^\d{1,20}$/)

    customtype = "number";
    decimal = intregx.test(sample) ? false : true;
  }

  return { type: customtype, decimal }
}

export const displayFormatter = ({ type, currency, percent, decimal }) => {
  switch (type) {
    case "number":
      //console.log('displayFormatter', currency, asBool(percent), asBool(decimal), name)
      const shouldAddDecimals = (decimal || currency) ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      const formatter = new Intl.NumberFormat('en-US', {
        style: (percent && "percent") || "decimal",
        ...shouldAddDecimals
      });

      return (dataCurrency) => {
        return {
          format: value => (isNaN(value) ? undefined : (formatter.format(value) + (currency && !percent ? (" " + (dataCurrency || currency)) : ""))),
          currency: dataCurrency || currency
        }
      }
    case "date":
      return () => ({ format: value => value && (new Date(Date.parse(value))).toLocaleDateString("es-DO", { timeZone: "UTC" }) })
    case "time":
      const _timeregx = new RegExp(/^([0-9]{1,2}:{0,1}){3}$/)

      return () => ({ format: value => value && (new Date(Date.parse(_timeregx.test(value) ? "2000/01/01 " + value : value))).toLocaleTimeString("es-DO") })
    case "datetime-local":
      return () => ({ format: value => value && (new Date(Date.parse(value))).toLocaleString("es-DO") })
    case "custom":
      return (_currency, _sample) => (displayFormatter({ ...typeFromSample(_sample), currency: _currency})(_currency))
    default:
      return () => ({ format: value => value })
  }
}

const booleanfieldfields = ['notaggregable', 'notremovable', 'select_custom', 'select_multi', 'select_lookup', 'required', 'readonly', 'percent', 'decimal', 'computed', 'preview', 'hideonboard', 'hideonform', 'clearable']

export const getEntityFromSample = (sample = {}) =>
  Object.entries(sample).map(([column, value]) => {
    const len = value.length || 15;
    const { type, decimal } = typeFromSample(value)
    return {
      name: column,
      title: column,
      path: column,
      type,
      decimal,
      size: len < 7 ? "xs" : (len < 16 ? "sm" : (len < 30 ? "md" : "lg")),
      formatter: displayFormatter({ type, decimal })
    }
  })


export const getEntity = (entityList = []) => {
  const entities = {}
  entityList.forEach(entity => {
    const fields = entity.field && JSON.parse(JSON.stringify((entity.field)))
    if (entity.name) entities[entity.name] = fields;
    if (entity.id) entities[entity.id] = fields;
  })

  //se completan las refrencias internas.
  entityList.forEach(entity => {
    if (entity.field) entity.field.forEach((field, idx) => {
      const newField = entities[entity.name || entity.id][idx]
      if (field.entity) newField.entity = entities[field.entity]; //se completan las refrencias internas.

      //boolean-fields
      booleanfieldfields.forEach(key => newField[key] = asBool(field[key]))

      if (field.flags) (field.flags?.split(";") || []).forEach(flag => newField[flag] = true);

      newField.formatter = displayFormatter(newField)
    })
  })
  return entities
}

export const getForm = (configForms, title, properties) => {
  return (configForms && configForms.length) ? configForms.map(form => ({
    title: form.title || (form.type === "header" && title) || undefined,
    type: form.type,
    name: form.name,
    entity: form.entity,
    dispatch: properties.dispatch,
    //pageData: properties.pageData,
    close: getTriggerAction({ actionNames: form.close, ...properties }),
    hasCloseActions: !!form.close,
    buttons: getButtons(form.button, properties),
  })) : []
}

export const getReader = (configReaders, properties, pageData) => {
  return (configReaders && configReaders.length) ? configReaders.map(({ params, ...reader }) => ({
    ...reader,
    property: resolveExpression(params, { global: pageData, local: pageData }),
    dispatch: properties.dispatch,
    action: getTriggerAction({ actionNames: reader.action, ...properties })
    //data: properties.pageData,
  }
  )) : []
}

export const getBoard = (configBoards, entity, properties) => {
  return (configBoards && configBoards.length) ? configBoards.map((board) => ({
    ...board,
    hideifempty: asBool(board.hideifempty),
    entity: entity[board.entity],
    buttons: getButtons(board.button, properties),
    hasButtons: !!board.button,
    click: getTriggerAction({ actionNames: board.click, ...properties }),
    hasClickActions: !!board.click,
    dispatch: properties.dispatch,
    //data: properties.pageData,
  })) : []
}


export const tryJSON = val => {
  let result;
  try {
    result = (val === "undefined" ? undefined : JSON.parse(val))
  } catch (error) {
    result = val
  }
  return result;
}
export const isValid = (val) => (val !== undefined && val !== null)
export const firstValid = (...vals) => vals.find(isValid)
export const asArray = (val) => val instanceof Array ? val : [val]

export const getPropertyList = ({ innerdata, outerdata, properties }) => {
  const result = {}
  const props = (properties instanceof Array) ? properties : [properties];

  props.forEach((property) => {
    let val

    switch (property.source) {
      case "self":
        val = tryJSON(property.value)
        updateObjectValue(result, property.key, val)
        return;
      case "rel":
        val = isValid(property.value) ? getObjectValue(innerdata, property.value) : undefined
        break;
      case "abs":
        val = isValid(property.value) ? outerdata[property.value] : undefined
        break;
      default:
        console.warn("unknown property source", property)
        return;
    }

    if (val !== undefined) updateObjectValue(result, property.key, val)
  })

  return result;
}

export const ifNaN = (a, b) => isNaN(a) ? b : a;

const isQuoted = text => typeof text === 'string' &&  (text.startsWith("'") || text.startsWith('"'))

export const getPropertyObject = (innerdata, outerdata, properties, cookies) => {
  const result = {}
  //console.log("solving properties", properties)
  const obj = {
    global: outerdata,
    local: innerdata,
    cookie: cookies,
  }
  Object.entries(properties).forEach(([key, val]) => {

    switch (key) {
      case "property": //ignore
      case "type": //ignore
        break;
      case "_stack":
      case "name":
      case "inner":
      case "actions":
      case "exception":
        result[key] = val;
        break;
      case "method":
      case "page":
      case "board":
      case "form":
      case "as":
      case "path":
      case "label":
        if (!isQuoted(val)) {
          result[key] = val;
          break
        }

      case "show":
      case "seconds":
      case "timeout":
      case "iteration":
      case "params":
      case "message":
      case "data":
      case "entity":
      case "info":
        result[key] = resolveExpression(val, obj)
        break;
      case "set":
        result[key] = val.map(({ cookie, maxage, path, path_exp, exp, compute }) => ({
          cookie,
          maxage: ifNaN(Number(maxage), null),
          path: path || (path_exp && resolveExpression(path_exp, obj)) || "data",
          data: asBool(compute) ? computeThis(exp, obj) : resolveExpression(exp, obj)
        }))
        break;
      default:
        console.warn("solving action property by default: ", key)
        result[key] = resolveExpression(val, obj)
    }
  })
  //console.log("resulting", result)
  return result;
}

/* esto debe resolver el problema de la comilla doble escapada. (puediera ser remplazando comillas dobles por simple, pero eso hay que probarlo primero...)
const safeResolveExpression = (exp, obj) => tryJSON(resolveExpression(JSON.stringify(exp), obj))
*/
