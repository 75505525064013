import React from 'react'
import Card from './card';
import TableElement from './table';
import db from '../../../services/service'
import Item from './item';
import { asArray, firstValid } from '../utils';

const SimpleHeader = ({ title }) => (
  <div className="card-header py-2" style={{ border: "0px" }}>
    {title && <h3 className="card-title"><b>{title}</b></h3>}
    <div className="card-tools"> </div>
  </div>
)


const SimpleItemsElement = ({ data, title, meta, close, fromForm = false, pageData }) => {
  const elements = asArray(firstValid(data, []));

  if (fromForm)
    return elements.map((card, idx) => <Item {...card} meta={meta} key={idx} close={close} pageData={pageData} localData={card} />)
  else
    return (
      <React.Fragment>
        <SimpleHeader title={title} />
        <div className="card-body p-2">
          <div className="row">
            {asArray(firstValid(data, [])).map((card, idx) => <Item {...card} meta={meta} key={idx} close={close} pageData={pageData} localData={ card } />)}
          </div>
        </div>
      </React.Fragment>
    )
}

const SimpleCardsElement = ({ data, title, meta}) => {
  const elements = asArray(firstValid(data, []))

  return (
    <React.Fragment>
      <SimpleHeader title={title} />
      <div className="card-body p-2">
        <div className="row">
          {elements.map((card) => <Card {...card} meta={meta} key={card.name} />)}
        </div>
      </div>
    </React.Fragment>
  )
}

const SimpleTableElement = ({ data, title, struct, click, meta }) => {
  return (
    <React.Fragment>
      <SimpleHeader title={title} />
      <div className="card-body table-responsive p-0 pb-1 border-top">
        <table className="table table-hover table-sm text-nowrap">
          {db.getTableDataFromList(data, click, struct, meta)}
        </table>
      </div>
    </React.Fragment>
  )
}
// })}
const LoadingElement = () => <div className="card-body"><p>cargando metadatos.</p></div>

const Selector = ({ type, ...props }) => {

  switch (type) {
    case "loading": return <LoadingElement {...props} />

    case "simple table": return <SimpleTableElement {...props} />
    case "simple cards": return <SimpleCardsElement {...props} />
    case "card": return <SimpleItemsElement {...props} />
    case "table": return <TableElement {...props} />

    default: return <div>{`Elemento desconocido "${type}"`}</div>
  }
}


export default Selector;
