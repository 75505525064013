import React, { useMemo, useEffect, useState, useCallback } from 'react'
import SimpleLabel from './simplelabel'
import { asBool } from './utils'
import * as isEqual from 'react-fast-compare';

const noop = () => { };
const SimpleSelect = ({
	name,
	label,
	placeholder,
	value: defaultValue,
	path,
	data,
	setNewVal,
	readonly,
	required,
	select_lookup,
	option
}) => {

	const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}${name}`
	//const option = useOptionList({ options, global: pageData, local: localData, label_path: option_label_path, value_path: option_value_path, name })
	const current = useMemo(() => option.find(item => item.value === (data || defaultValue)), [defaultValue, option, data])
	const isLookup = asBool(select_lookup)

	const props = {}
	if (asBool(readonly)) props.disabled = true;
	if (asBool(required)) props.required = true;

	const [lookupKeys, setLookupKeys] = useState(undefined);

	const onChange = useCallback(val => {
		//console.log("onChanged", val)
		setNewVal(val || undefined)
	}, [setNewVal])

	const isLoading = !option.length

	useEffect(() => {
		//console.log("¿changed?", current, data, isLookup, isLoading, lookupKeys, path)
		if (isLoading) return;

		if (isLookup) {
			let val = current
			const prePath = path.split(".");
			prePath.pop();

			const { label, ...item } = val || {};

			const oldkeys = [...(lookupKeys || [])];
			const newkeys = Object.keys(item);

			if (!isEqual(newkeys, lookupKeys)) setLookupKeys(newkeys);

			newkeys.forEach(k => {
				const idx = oldkeys.indexOf(k);
				if (idx !== -1) oldkeys.splice(idx, 1);

				if (k === "value") data === item[k] ? setNewVal(item[k]) : noop();
				else setNewVal(item[k], [...prePath, k].join("."))
			})

			oldkeys.forEach(k => (k === "value") ? (data ? setNewVal(undefined) : noop()) : setNewVal(undefined, [...prePath, k].join(".")))
		}
		else onChange(current?.value)
	}, [isLoading, current, onChange, data, isLookup, lookupKeys, path, setNewVal])


	return (
		<React.Fragment>
			<SimpleLabel label={label} htmlFor={_id} />
			<select id={_id}
				className="custom-select custom-select-sm"
				name={name}
				{...props}
				value={current?.value || ''}
				onChange={e => onChange(e.target.value)}
			>
				{
					[{ value: "", label: (isLoading ? "Cargando..." : placeholder || "Seleccione") }, ...option].map((opt, idx) => <option key={idx} value={opt.value} {...((opt.value === "" && required) ? { disabled: true } : {})} >{opt.label}</option>)
				}
			</select>
		</React.Fragment>
	)
}

export default SimpleSelect;
