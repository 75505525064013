import React, { useReducer, useMemo, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { getBoard, getForm, getTriggerAction, getEntity, getReader, syncQueryString, getObjectValue, getActionListOuterData } from './utils'
import { useLocation, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAlert } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies } from 'react-cookie';

import Event from './event'
import Reader from './reader'
import Board from './board'
import Form from './form'
import { eventAlertContext, modalAlertContext } from '../../app';
import Print from '../print';
import { reducer, observerReducer, actionReducer, actionProcessor } from './index.reducer';

const Header = ({ title = "Portal Grupo Propagas", processing }) => (
  <div className="col mb-2">
    <Helmet>
      <title>{`${title} - Portal GP`}</title>
    </Helmet>
    <h1 className="d-inline mr-2 ">{title}</h1>
    {processing && <span><FontAwesomeIcon icon={["fas", "cog"]} spin size="sm" /> </span>}
  </div>
)

const Page = ({
  title,
  entity: entityList = [],
  reader: configReaders = [],
  board: configBoards = [],
  form: configForms = [],
  action,
  data: configData,
  setup,
  refresh,
  context,
  cookies
}) => {
  //console.log("spam")

  const [data, dispatch] = useReducer(reducer, (configData && JSON.parse(configData)) || {})
  const [, observerDispatch] = useReducer(observerReducer, {})
  const [actions, actionDispatch] = useReducer(actionReducer, []);

  const alert = useAlert(modalAlertContext);
  const event = useAlert(eventAlertContext);

  const urlParams = JSON.stringify(useParams());
  const currentScope = context?.scope?.current;
  const currentMeta = data.meta;
  const currentQuery = data.query;
  const locationSearch = useLocation().search;
  const locationPath = useLocation().pathname;
  const historyPush = useHistory().push;
  const contextDispatch = context.dispatch;
  const currentAction = actions[0];

  useEffect(() => {
    return () => {
      observerDispatch({ type: "dettach-all" });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "set-meta",
      payload: {
        scope: currentScope,
        params: JSON.parse(urlParams),
        location: {
          search: locationSearch,
          path: locationPath
        },
        history: {
          push: historyPush
        },
        alert: alert.show,
        event: event.show,
        refresh: () => { contextDispatch({ type: "refresh-data", payload: true }) },
        refreshed: () => { contextDispatch({ type: "refreshed" }) },
        run: (actions = [], data = {}, close = () => { }, processingFlagPath = "") => {
          if (processingFlagPath) dispatch({ type: "set-data", path: processingFlagPath, payload: actions.length });
          actionDispatch({ type: "add", actions: { tasks: actions, data, close, processingFlagPath } })
        },
        actionDispatch,
        observerDispatch,
        cookies
      }
    });
  }, [contextDispatch, locationSearch, locationPath, historyPush, alert, currentScope, cookies, urlParams])

  const [init, setInit] = useState(false);


  useEffect(() => {
    if (currentMeta && !init) {
      setInit(true);
      console.log("initializing", currentMeta, (configData && JSON.parse(configData)) || {});

      getTriggerAction({
        actionNames: setup,
        meta: currentMeta
      })()()

    }
  }, [setup, init, configData, currentMeta])

  const [neededData, setNeededData] = useState({});

  useEffect(() => {
    let [newNeeded, shouldUpdate] = getActionListOuterData(action, data, neededData)

    if (data.query instanceof Object) { //query expected
      Object.entries(data.query).forEach(([key, detail]) => {
        newNeeded[detail] = newNeeded[detail] || getObjectValue(data, detail)
        if (neededData[detail] !== newNeeded[detail]) shouldUpdate = true;
      })
    }

    if (shouldUpdate) {
      //console.log("setting up new Needed Data", neededData, newNeeded);
      setNeededData(newNeeded);
    }
  }, [action, data, neededData]);

  const properties = useMemo(() => ({
    dispatch: dispatch,
    meta: currentMeta,
    query: currentQuery,
    scope: currentScope
  }), [dispatch, currentMeta, currentScope, currentQuery])

  useLayoutEffect(() => {
    syncQueryString({ ...properties, outerData: neededData });
  }, [properties, neededData])

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    actionProcessor({ currentAction, processing, action, actionDispatch, setProcessing, dispatch, data, currentMeta, currentScope })()
  }, [processing, currentAction, currentScope, data, dispatch, currentMeta, action])


  const entity = useMemo(() => getEntity(entityList), [entityList]);
  const forms = useMemo(() => getForm(configForms, title, properties), [configForms, title, properties])
  const readers = useMemo(() => properties.meta ? getReader(configReaders, properties, data) : [], [configReaders, properties, data])
  const boards = useMemo(() => properties.meta ? getBoard(configBoards, entity, properties) : [], [configBoards, entity, properties])

  //eleva al app el refresh disparado por action.
  useEffect(() => {
    if (data.refresh) {
      dispatch({ type: "set-data", path: "refresh", payload: false })
      context.dispatch({ type: "refresh-data", payload: true })
    }
  }, [context, data.refresh])

  useEffect(() => {
    if (context.shouldRefresh && refresh) {
      contextDispatch({ type: "refreshing" })
      getTriggerAction({
        actionNames: refresh.concat(";refreshed"),
        meta: currentMeta
      })()()
    }
  }, [refresh, contextDispatch, context.shouldRefresh, currentMeta])

  const formHeader = forms.find(form => form.type === "header")
  const activeForms = (data.forms instanceof Array) ? data.forms : []
  const hiddenBoards = (data.hidden instanceof Array) ? data.hidden : []
  const filteredBoards = (typeof data.filtered === 'object') ? data.filtered : {}

  useLayoutEffect(() => {
    if (context?.refreshing != data._refreshing) {
      dispatch({ type: "set-data", path: "_refreshing", payload: context?.refreshing })
    }
  }, [data._refreshing, context?.refreshing])

  const DisplayBoard = useCallback(({ boardName, ...props }) => {
    const board = boards.find(({ name }) => name === boardName)

    return board
      ? <Board key={board.name}
        //hidden={hiddenBoards.includes(board.name)}
        filtered={filteredBoards[board.name]}
        data={data}
        {...props}
        {...board}
        meta={currentMeta}
        dispatch={dispatch}
        refreshing={context.refreshing}
      />
      : <span>{`Board ${boardName} not defined.`}</span>
  }, [context.refreshing, currentMeta, hiddenBoards, data, boards])

  return (
    <React.Fragment>
      <div className="content-header" >
        {formHeader
          ? <Form
            Header={() => (<Header title={data.header?.title || formHeader.title || title} processing={processing} />)}
            path="header"
            processingFlagPath="header.processing"
            processing={data.header?.processing}
            data={data.header?.data}
            pageData={data}
            struct={entity[data.header?.entity || formHeader.entity]}
            {...formHeader}
            DisplayBoard={DisplayBoard}
          />
          : <section className="container-fluid">
            <div className="row">
              <Header title={title} processing={processing} />
            </div>
          </section>
        }
      </div>
      <div className="content">
        <section className="container-fluid">
          {
            activeForms.map(((active, i) => {
              if (!active) return null;

              const props = forms.find((form) => form.name === active.name && (form.type === "card" || form.type === "modal"))
              const closefn = () => {
                dispatch({ type: "close-form", index: i });
                return { closed: true }
              }
              return props &&
                <Form
                  key={i}
                  path={`forms.${i}`}
                  processingFlagPath={`forms.${i}.processing`}
                  processing={active.processing}
                  data={active.data}
                  reset={active.reset}
                  pageData={data}
                  struct={entity[active.entity || props.entity]}
                  {...props}
                  DisplayBoard={DisplayBoard}
                  title={active.title || props.title}
                  closefn={closefn}
                />
            }))
          }
          {readers.map((reader) => <Reader key={reader.name} {...reader} data={data} context={context} />)}
          {
            boards.map((board) =>
              <Board key={board.name}
                hidden={hiddenBoards.includes(board.name)}
                filtered={filteredBoards[board.name]}
                data={data}
                {...board}
                meta={currentMeta}
                dispatch={dispatch}
                refreshing={context && context.refreshing}
              />)
          }
          <Event data={data} processing={processing} init={init} locationSearch={locationSearch} action={useCallback(() => setInit(false), [])} />
          <Print context={context} {...(data.print)} dispatch={dispatch} />
        </section>
      </div>
    </React.Fragment>
  )
}

export default withCookies(Page)