import React from 'react'
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './menuitem.css'

const collapseSidebar = () => { if (document.body.classList.contains("sidebar-open")) window.jQuery('[data-widget="pushmenu"]').PushMenu('toggle') }

const MenuItem = ({ nivel = 0, menu = [] }) => menu?.map((option, i) => {
	const padding = 0.5 * nivel + 0.5

	const style = {
		navlink: {
			maxWidth: "250px",
			paddingLeft: `${padding}rem`,
			paddingRight: ".5rem"
		},
		text: {
			maxWidth: `calc(250px - 2.35rem - ${padding}rem - ${(option.menu || option.menulist) ? 1.2 : 0}rem)`
		}
	}

	if (option.menu || option.menulist)
		return (
			<li key={i} className="nav-item" >
				<NavLink id={`navlink${nivel}_${i}`} to={l => l} data-toggle="collapse" data-target={`#navtree${nivel}_${i}`} className="nav-link" style={style.navlink} activeClassName="" >
					<FontAwesomeIcon icon={option.icon.split(" ")} className="nav-icon" />
					<p className="text-truncate align-bottom d-inline-block" style={style.text} >{option.label}
						<FontAwesomeIcon color="gray" size="1x" icon={['fas', 'angle-left']} className="right"/>
					</p>
				</NavLink>
				<ul id={`navtree${nivel}_${i}`} className="nav d-block-ni flex-column nav-sidebar collapse" >
					<MenuItem nivel={nivel + 1} menu={option.menu || option.menulist} />
				</ul>
			</li>
		)
	else if (option.page && option.icon)
		return (
			<li key={i} className="nav-item" >
				<NavLink exact onClick={collapseSidebar} to={`/${option.page}`} className="nav-link" style={style.navlink} activeClassName="active" >
					<FontAwesomeIcon icon={option.icon.split(" ")} className="nav-icon" />
					<p className="text-truncate align-bottom d-inline-block" style={style.text} >{option.label || option.page}</p>

				</NavLink>
			</li>
		)
	else if (!option.page)
		return <li key={i} className="nav-header" >{option.label}</li>
	else return null
})

export default MenuItem;
