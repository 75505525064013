import React, { useEffect, useState, useMemo, useCallback } from 'react'
import SimpleLabel from './simplelabel'
import NumberFormat from 'react-number-format';

const SimpleNumber = ({ name, label, placeholder, value, path, data, setNewVal, readonly, required, decimal, formatter, percent, currency, dataCurrency, max, min }) => {
	const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}`
	const current = (data !== undefined ? data : value)
	const [edditing, setEdditing] = useState(false);

	const [buffer, setBuffer] = useState(current)
	const [inputElm, setInputElm] = useState(null);

	useEffect(() => {
		//const active = inputElm === document.activeElement

		if (buffer !== current) {
			//console.log(inputElm)
			if (edditing) {
				//console.log('number changed, skiped by focus', current, buffer, name)
			} else {
				//console.log("number changed", buffer, current, name, edditing)
				
				const mlt = decimal ? 100 : 1

				if (Math.round(current * mlt) / mlt === buffer)
					setNewVal(buffer)
				else
					setBuffer(current);
			}
		}
	}, [setNewVal, buffer, current, edditing, decimal])

	useEffect(() => {
		if (data === undefined && current !== undefined) {
			setNewVal(current)
		}
	}, [current, path, setNewVal, data])

	const fieldFormatter = useMemo(() => formatter(dataCurrency), [formatter, dataCurrency])

	const props = useMemo(() => {
		//console.log("ok", dataCurrency, min, max, percent, currency)
		
		const props = {}
		if (readonly) props.disabled = true
		if (required) props.required = true

		if (percent) props.suffix = " %"
		else if (currency) props.suffix = " " + fieldFormatter.currency

		if (decimal || currency) props.decimalScale = 2
		else props.decimalScale = 0

		return props
	}, [required, fieldFormatter.currency, readonly, percent, currency, decimal])

	const onValueChange = useCallback((values) => {
		const active = inputElm === document.activeElement
		const fval = (values.floatValue / (percent ? 100 : 1))

		if ((active && edditing)) {
			//console.log('valuechanged inside', values.floatValue, name, edditing, active)
			setBuffer(values.floatValue === null || values.floatValue === undefined ? undefined : fval)
		} else {
			//console.log('valuechanged outside', values.floatValue, buffer, name, edditing, active)
			if (values.floatValue !== buffer) setNewVal(fval)
		}
	}, [setNewVal, buffer, inputElm, percent, edditing])

	const onBlur = useCallback((e) => {
		//console.log('blured', buffer)
		setEdditing(false)
		const validity =
			((min !== undefined && buffer < min) && `El valor debe ser superior o igual a ${fieldFormatter.format(min)}`) ||
			((max !== undefined && buffer > max) && `El valor debe ser inferior o igual a ${fieldFormatter.format(max)}`) ||
			""
		if (buffer !== undefined) e.target.setCustomValidity(validity)
		setNewVal(buffer)
	}, [setNewVal, buffer, min, max, fieldFormatter])

	return (
		<React.Fragment>
			<SimpleLabel label={label} htmlFor={_id} />
			<NumberFormat id={_id}
				name={name}
				className="form-control form-control-sm text-weight-normal"
				displayType="input"
				getInputRef={setInputElm}
				type="text"
				allowEmptyFormatting={false}
				thousandSeparator={true}
				fixedDecimalScale={true}
				placeholder={placeholder}
				value={buffer === undefined ? undefined : (buffer * (percent ? 100 : 1))}
				{...props}
				onValueChange={onValueChange}
				onBlur={onBlur}
				onKeyDown={() => { setEdditing(true) }}
				
			/>
		</React.Fragment >
	)
}

export default SimpleNumber;
