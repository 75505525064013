import React from 'react'
import { Link } from 'react-router-dom'
import { getTriggerAction } from '../page/utils'

const LinkComponent = ({ title, children, href, meta, close, localData}) => {

  const clickHandle = e => {
    e.preventDefault()
    e.stopPropagation()
    getTriggerAction({ actionNames: href, meta})(localData, close)()
  }

  return <Link to="#" title={title} onClick={clickHandle } >{children}</Link>
}

export default LinkComponent