import React, { useReducer, useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import authService from './service'
import { ApplicationPaths as AuthPaths, QueryParameterNames as ParNames } from './constants'
import config from '../../config'

const initialState = config.testAuthState || {
  ready: false,
  authenticated: false,
  suscription: null,
  user: null,
}

const AuthContext = React.createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case "setAuth":
      return { ...state, ...action.payload }
    case "subscribe":
      return { ...state, subscription: action.payload }
    case "unsubscribe":
      authService.unsubscribe(state.subscription);
      return { ...state, subscription: null }
    default:
      throw new Error(`Authroute: acción desconocida: ${JSON.stringify(action)}`)
  }
}

async function populateAuthState(dispatch) {
  const [authenticated, user, scopes] = await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.getScopes()])
  dispatch(true, authenticated, { name: user && user.name, scopes});
}

const AuthRoute = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const loc = useLocation();
  const isPublic = loc.pathname.startsWith("/public/");

  useEffect(() => {

     if (config.testAuthState) return;

    const setAuth = (ready, authenticated, user) => dispatch({ type: "setAuth", payload: { ready, authenticated, user } });

    dispatch({
      type: "subscribe", payload: authService.subscribe(() => {
        setAuth(false, false, null) 
        populateAuthState(setAuth)
      })
    })
    populateAuthState(setAuth)

    return () => dispatch({ type: "unsubscribe" })
  }, [])

  const { ready, authenticated, user } = state
  const { loading: Loading, component: Component, ...rest } = props;
  const redirectUrl = {
    pathname: AuthPaths.LoggedOut,
    search: `?${ParNames.ReturnUrl}=${encodeURI(window.location.href)}`,
  }

  if (!ready) {
    return <div>Cargando...</div>
  } else {
    return <Route {...rest}>
      {
        authenticated || isPublic
        ? <AuthContext.Provider value={user}> <Component {...props} /> </AuthContext.Provider> 
        : <Redirect to={redirectUrl} {...props} />
      } 
    </Route>
  }
}

export default AuthRoute

export { AuthContext }
