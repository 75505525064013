import React, { useReducer, useEffect, useLayoutEffect, createContext } from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import DialogAlert from './components/alert/dialog';

import Layout from './components/layout/index'
import AuthRoute from './components/auth/authroute';
import ApiAuthorizationRoutes from './components/auth/routes';
import { ApplicationPaths } from './components/auth/constants';

import { getScope, apiFetch } from './services'
import FrontDoor from './components/alert/frontdoor';
import EventTemplate from './components/alert/eventtemplate';
import config from './config';
import * as isEqual from 'react-fast-compare';

const initState = {
  methods: {},
  shouldRefresh: false,
  refreshing: 0,
  scope: null
}

export const eventAlertContext = createContext();
export const modalAlertContext = createContext();

const eventAlertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '10px',
  transition: transitions.FADE
}


const modalAlertOptions = {
  position: positions.MIDDLE,
  timeout: 0,
  offset: '35px',
  transition: transitions.FADE
}

export const DataContext = React.createContext(initState)

const reducer = (state, action) => {
  //console.log("app dispatch", action);
  const acc = ({ empresa, tipo, id }) => ({ empresa, tipo, id });

  switch (action.type) {
    case 'set-dispatch':
      return { ...state, dispatch: action.payload };
    case 'add-public-scope':
      const publicScope = { empresa: "gp", tipo: "público", id: "invitado" }
      if (!state.scope) {
        return { ...state, scope: { accounts: [publicScope], current: publicScope } }
      }

      return state;
    case 'set-scope':
      let current = state.initAccount && action.payload?.accounts?.find(a => isEqual(acc(a), state.initAccount))

      if (!current) current = action.payload?.accounts && action.payload.accounts[0]

      const scope = { ...(action.payload), current }
      return { ...state, scope };
    case 'set':
      return { ...state, ...(action.payload) };
    case 'set-account':
      state.setCookie("gp_account", acc(action.payload), { sameSite: true, path: "/" })
      return { ...state, scope: { ...(state.scope), current: action.payload } };
    case 'refresh-data':
      if (state.shouldRefresh === action.payload) return state; //no-change on multiple clicks
      return { ...state, shouldRefresh: action.payload };
    case 'refreshing':
      if (state.refreshing === 1) return { ...state, refreshing: state.refreshing + 1, lastRefresh: "Refrescando" }
      return { ...state, refreshing: state.refreshing + 1 };
    case 'refreshed':
      const options = {
        year: '2-digit', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', second: '2-digit'
      };

      if (state.refreshing === 1) {
        return { ...state, refreshing: 0, shouldRefresh: false, lastRefresh: Intl.DateTimeFormat('es-DO', options).format(Date.now()) }
      }
      return { ...state, refreshing: state.refreshing - 1 };
    case 'update-method':
      const olddata = state.methods[action.payload.method]
      const newdata = {}
      newdata[action.payload.method] = { ...olddata, ...action.payload.data }

      return { ...state, methods: { ...state.methods, ...newdata } }
    default: throw Error(`unknown action ${action}`)
  }
}

const Init = ({ context, ...props }) => {
  const dispatch = context.dispatch;
  const loc = useLocation();

  const isPublic = loc.pathname.startsWith("/public/");

  useEffect(() => {
    if (dispatch) {
      getScope(({ _response, ...scope }) => {
        if (_response.ok) dispatch({ type: "set-scope", payload: scope })
        else if (isPublic) dispatch({ type: "add-public-scope" })
      })
    }
  }, [dispatch, isPublic])

  return <FrontDoor message="Inicializando..." />
}

const appVersion = (process.env.REACT_APP_VER)
/*
const MyApp = () => {
  const [a, setA] = useState(0);
  const b = useRef(null)


  useEffect(() => {
    console.log("a a cambiado a", a, b)
    if (a < 2) setA(2);
    else if (a < 3) setA(3);
    else if (a < 4) setA(4);
    return () => { console.log("a va a cambiar de ", a, b) }
  }, [a])

  useEffect(() => {
    console.log("montando", "MyApp")
    b.current = "ok"
    setA(1);
    return () => { console.log("desmontando", "MyApp") }
  }, [])

  return (
    <div>
      <JSONEditor modes={['tree', 'view', 'code']} json={{
        'array': [1, 2, 3],
        'boolean': true,
        'null': null,
        'number': 'four',
        'object': { 'a': 'b', 'c': 'd' },
        'string': 'Hello World'
      } } />
    </div>
    )
}
*/

const App = () => {
  const [cookies, setCookie] = useCookies(["gp_account", "gp_defaultAccount", "gp_refreshed"]);
  const [state, dispatch] = useReducer(reducer, { ...initState, initAccount: cookies.gp_account || cookies.gp_defaultAccount, setCookie: setCookie })

  const server_version = cookies.gp_refreshed ? undefined : (state.app && state.app[0]?.version);

  useEffect(() => {
    if (server_version && appVersion && server_version > appVersion) {
      setCookie("gp_refreshed", server_version, { maxAge: 3600, path: "/" })
      window.location.reload();
    }
  }, [server_version, setCookie])

  useLayoutEffect(() => {
    dispatch({ type: "set-dispatch", payload: dispatch })
    //muestra el sidebar al inicio en pantallas grandes.
    if (window.innerWidth > 1400) {
      document.body.classList.remove("sidebar-collapse")
    }
  }, [])

  useEffect(() => {
    if (state.shouldRefresh) dispatch({ type: "refresh-data", payload: false })
  }, [state.shouldRefresh])

  useEffect(() => {
    if (state.scope) {
      if (config.testPages) dispatch({ type: "set", payload: config.testPages })
      else apiFetch("/exec", { method: "pages_get" }, state.scope.current)
        .then(resp => dispatch({ type: "set", payload: { ...resp } }))
    }
  }, [state.scope])

  //return <MyApp />

  return (
    <DataContext.Provider value={state}>
      <AlertProvider template={EventTemplate} {...eventAlertOptions} context={eventAlertContext} >
        <AlertProvider template={DialogAlert} {...modalAlertOptions} context={modalAlertContext} >
          <Switch>
            {/*<Route path={ApplicationPaths.ApiPublicPrefix} component={PublicPage} />*/}
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            <AuthRoute context={state} component={state.scope ? Layout : Init} />
          </Switch>
        </AlertProvider>
      </AlertProvider>
    </DataContext.Provider>
  )
}

export default App

