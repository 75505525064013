import { useState, useEffect } from 'react';
import { useMemo } from 'react';

const isSmall = window.matchMedia('(max-width: 575px)');
const isLarge = window.matchMedia('(min-width: 1200px)');
const getwsize = (sm, lg) => sm ? 0 : (lg ? 2 : 1)
const sizes = [
	{ //small
		xs: { minWidth: "7rem", maxWidth: "50%" },
		sm: { minWidth: "12rem", maxWidth: "100%" },
		md: { minWidth: "66%", maxWidth: "100%" },
		lg: { minWidth: "100%", maxWidth: "100%" },
		xl: { minWidth: "100%", maxWidth: "100%" },
		full: { minWidth: "100%", maxWidth: "100%" },
	},
	{ //standard
		xs: { minWidth: "8rem", maxWidth: "20%" },
		sm: { minWidth: "13rem", maxWidth: "50%" },
		md: { minWidth: "18rem", maxWidth: "50%" },
		lg: { minWidth: "50%", maxWidth: "100%" },
		xl: { minWidth: "100%", maxWidth: "100%" },
		full: { minWidth: "100%", maxWidth: "100%" },
	},
	{ //large
		xs: { minWidth: "8rem", maxWidth: "15%" },
		sm: { minWidth: "13rem", maxWidth: "20%" },
		md: { minWidth: "20rem", maxWidth: "50%" },
		lg: { minWidth: "50%", maxWidth: "70%" },
		xl: { minWidth: "70%", maxWidth: "100%" },
		full: { minWidth: "100%", maxWidth: "100%" },
	},
]

export const useSizeStyle = (size) => {
	const [wsmall, setwsmall] = useState(isSmall.matches);
	const [wlarge, setwlarge] = useState(isLarge.matches);

	useEffect(() => {
		const smallhandler = e => setwsmall(e.matches)
		const largehandler = e => setwlarge(e.matches)

		isSmall.addListener(smallhandler);
		isLarge.addListener(largehandler);

		return () => {
			isSmall.removeListener(smallhandler)
			isLarge.removeListener(largehandler)
		}
	}, [])

	return useMemo(() => sizes[getwsize(wsmall, wlarge)][size], [wsmall, wlarge, size]);
}

