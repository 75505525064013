import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const defaultSolver = (cond, style) => {
	if (cond) console.log("no shouldButtonDisplay solver defined for condition")
	return { style }
}

const Button = ({ solver = defaultSolver, condition, idx, trigger, icon, label, placeholder, triggerFn, style = "default", validate = false, formRef }) => {

	const clickHandler = (validate ? () => {
		formRef.current.requestSubmit();
		if (formRef.current.checkValidity()) triggerFn(trigger)();
	} : triggerFn(trigger))

	const props = solver(condition, { minWidth: "90px", width: "100%" })

	return (
		<div className={`${!idx && ""} col-12 col-sm-6 col-md-auto`} style={{ display: props.style.display }} >
			<button {...props} title={placeholder} type="button" onClick={clickHandler} className={`btn btn-${style} btn-sm my-1 pl-1`}  >
				{icon && <FontAwesomeIcon className="mr-2" icon={icon.split(" ")} />}
				<span>{label}</span>
			</button>
		</div>
	)
}

export default Button;