import React from 'react'
import List from '../list'
import Draggable from 'react-drag-listview'
import { asBool } from './utils'
import ListLabel from './listlabel'
import { buttonCell } from '../../board/table/parsecolumns'
import { useEffect } from 'react'
import { asArray, isValid } from '../../utils'

const SimpleList = ({ name, button, value: defaultData, label, entity = [], notaggregable, dispatch, path, data, setNewVal, max, ...props }) => {
  const _id = `input${path.replace(/([._-]+)/g, '')}`
  const childcount = data?.length || 0
  const canAdd = asBool(notaggregable) || asBool(props.noheader) ? false : (isNaN(max) ? true : childcount < Number(max))
  const dragProps = {
    onDragEnd: (fromIndex, toIndex) => {
      const neword = data;
      const item = neword.splice(fromIndex, 1)[0];
      neword.splice(toIndex, 0, item);
      dispatch({ type: "set-value", path: path, payload: neword })
    },
    nodeSelector: 'li',
    handleSelector: `.drag-handle-${path.replace(/([._-]+)/g, '')}`
  }

  //add default value to data
  useEffect(() => {
    if (!isValid(data) && isValid(defaultData)) asArray(defaultData).forEach(setNewVal)
  }, [defaultData, data, setNewVal])

  return (
    <React.Fragment>
      { (button || label || canAdd) &&
        <div className="w-100 d-flex" >
          <ListLabel label={label} htmlFor={_id} canAdd={canAdd} add={setNewVal} />
          <div className="d-inline mt-2 mb-1">
            {buttonCell(props.pageData.meta, { path: "buttons", data: { path: String().concat(props.form_path, ".", path), children: data } })({}, undefined, button)}
          </div>
        </div>
      }
      <Draggable {...dragProps}>
        <ol id={_id} className="list-group border" >
          <List struct={entity} data={data} dispatch={dispatch} path={path} {...props} />
        </ol>
      </Draggable>
    </React.Fragment>
  )
}

export default SimpleList;
