import { testCard } from "./config.testcard"

export const testPage = {
  "name": "testpage",
  "type": "empleado",
  "title": "Pruebas de Markdown",
  "orden": "20",
  setup: "mensajito",
  "data": JSON.stringify(
    {
      header: {
        data: {
          texto: "Santo Domingo"
        }
      },
      algo: [
        {
          title: "Hola, Magno",
          detail: "Esto es un ejemplo de un item con un texto interesante que se puede informar al usuario."
        }
      ]
    }
  ),
  "reader": [],
  "form": [
    {
      "name": "test",
      "entity": "test",
      "type": "header",
    }
  ],
  "action": [
    {
      name: "mensajito",
      type: "alert",
      message: '{ "actions": "Enviar;Corregir;Klk", "bodymd": "#### Hola, Leandro \\n\\n> Este es un mensaje de error **bien** bonitoso.\\n\\n## La idea es provocar un mensaje tan largo que\\n\\nTodo el mundo deba hacer algo al respecto\\n\\nSi todo el mundo ataca\\n\\nentonces\\n\\ntaca taca...\\n\\n#### Hola, Leandro \\n\\n> Este es un mensaje de error **bien** bonitoso.\\n\\n## La idea es provocar un mensaje tan largo que\\n\\nTodo el mundo deba hacer algo al respecto\\n\\nSi todo el mundo ataca\\n\\nentonces\\n\\ntaca taca.... " }'
    },
    {
      "name": "postthis",
      "type": "post",
      "method": "\"guardarcambios\"",
      "data": "\"@local\"",
      "as": "\"datos\"",
      "path": "\"resultado\"",
      "param": "null",
      "exception": "ignore"
    },
    {
      "name": "logthis",
      "type": "log",
      "info": "\"@local\""
    },
    {
      name: "setthis",
      type: "set",
      set: [
        { path: "rutaimportante", compute: true, exp: `" '@local.texto;'.concat(' hola leandro')" ` },
      ],
    }
  ],
  "board": [
    {
      name: "pruebacard",
      type: "card",
      path: "algo",
      hidden: true
    }
  ],
  "entity": [
    {
      "name": "test",
      "field": [
        {
          name: "texto",
          type: "textarea",
          label: "Texto",
          size: "md"
        },
        {
          name: "s0",
          type: "section"
        },
        {
          name: "buttons",
          type: "button",
          value: '"ok"',
          action: "logthis;setthis"
        },
        {
          name: "s1",
          label: "Info",
          type: "section",
          modes: "read",
          readmd: `
#### Hola, *Mundo*

@local.texto;

:board[pruebacard]{path="" header=Hola size=md icon="fas coins" align=left title="Hola, Leandro" detail="jajajaja" action="logthis"}
:board[pruebacard]{path="" size=sm align=left detail="que cosas tiene la vida" action="logthis"}

`
        },
      ]
    },
    {
      name: "lista",
      field: [
        { name: "campo1", type: "text", label: "Un Campo", size: "md" },
        { name: "campo2", type: "text", label: "Otro Campo", size: "md" },

      ]
    }
  ]
}

export default testPage
