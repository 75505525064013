
import React, { useRef, useEffect, useState } from 'react';

import JSONEditorObj from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import cloneDeep from 'lodash.clonedeep';

import isEqual from 'react-fast-compare';

const JSONEditor = ({ text, json, ...props }) => {
    const jsonElm = useRef(null)
    const jsonObj = useRef(null)
    const init = useRef(false);
    const [options] = useState(props);
    const [schema, setSchema] = useState(cloneDeep({ schema: props.schema, schemaRefs: props.schemaRefs }))

    useEffect(() => {
        jsonObj.current = new JSONEditorObj(jsonElm.current, options)

        init.current = true;
        return () => { jsonObj.current?.destroy() }
    }, [options])

    useEffect(() => {
        if (init.current) {
            if (text) jsonObj.current.setText(text);
            if (json) jsonObj.current.set(json);
            setSchema(cloneDeep({ schema: props.schema, schemaRefs: props.schemaRefs }))
            init.current = false;
        } else {
            if (text) jsonObj.current.updateText(text);
            if (json) jsonObj.current.update(json);
            if (props.mode) jsonObj.current.setMode(props.mode);
            
            if (!isEqual(schema, { schema: props.schema, schemaRefs: props.schemaRefs })) {
                setSchema(cloneDeep({ schema: props.schema, schemaRefs: props.schemaRefs }))
                jsonObj.current.setSchema(props.schema, props.schemaRefs)
            }
        }
        
    }, [text, json, props.mode, schema, props.schema, props.schemaRefs])

    return <div className="jsoneditor-react-container" ref={jsonElm} />
}

export default JSONEditor;
