import canje from './canje'
import carpeta from './carpeta';
import conduce from './conduce';

const template = {
    canje,
    carpeta,
    conduce,
}

export default template;

