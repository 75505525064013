import { resolveExpression } from "./utils"
import * as isEqual from "react-fast-compare";
import { asBool } from "./form/input/utils";

const buildCondition = (cond, obj) => ({
	type: cond.type,
	not: asBool(cond.not),
	a: cond.a && resolveExpression(cond.a, obj),
	b: cond.b && resolveExpression(cond.b, obj),
	inner: (cond.child || cond.condition)?.map(inner => buildCondition(inner, obj)),
	result: cond.result
})

const shouldNot = (not, fn, result) => {
	let val

	try {
		val = fn()
	} catch (e)	{
		//console.info('evaluate failed:', e.message);
		val = false;
	}

	val = not ? !val : val;

	return val || result || false
}

const evaluateCondition = (cond) => shouldNot(cond.not, () => {
	//console.log('evaluando', cond);

	switch (cond.type) {
		case "istrue": return !!asBool(cond.a)
		case "exists": return (cond.a !== undefined && cond.a !== null && cond.a !== "" && !isEqual(cond.a, []) && !isEqual(cond.a, {})) 
		case "contains": return typeof cond.a === "string" && typeof cond.b === "string" ? cond.a.includes(cond.b) : false
		case "eq": return isEqual(cond.a, cond.b)
		case "greater": return cond.a > cond.b
		case "smaller": return cond.a < cond.b
		case "and": return cond.inner?.every(inner => evaluateCondition(inner))
		case "or": return cond.inner?.some(inner => evaluateCondition(inner))
		default: return false;
	}
}, cond.result) 

export const resolveCondition = (_condition, object) => {
	const condition = _condition?.length && buildCondition(_condition[0], object)

	return condition ? evaluateCondition(condition) : true; //if no condition then resolve as true.
}

