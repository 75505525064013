import { jsPDF } from "jspdf";
/**
 *
 * @param { {
 *   codigo_carpeta: string
 * } props
 *  
 */
const Carpeta = (props) => {
    const param = props
    const splitTextAndGetHeight = (text, size) => {
        var lines = doc.splitTextToSize(text, size);
        return {
            text: lines,
            height: doc.getTextDimensions(lines).h,
        };
    };

    const options = {
        format: [110, 160]
    };

    var doc = new jsPDF(options);

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    //starting at 15mm
    var currentHeight = 15;

    var marginX = 5

    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
        headerTextSize: 18,
        subHeaderTextSize: 16,
        labelTextSize: 14,
        fieldTextSize: 12,
        lineHeight: 6,
        subLineHeight: 4,
    };

    doc.setFontSize(pdfConfig.headerTextSize);
    doc.setTextColor(colorBlack);

    doc.text(marginX, currentHeight, param.empresa || "");
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(pdfConfig.subHeaderTextSize);
    doc.text(marginX, currentHeight, param.sucursal || "");

    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Código Carpeta", docWidth / 2, currentHeight, { align: "center", lang: "es" })

    currentHeight += pdfConfig.subLineHeight;

    //line breaker after logo & business info
    //if (param.invoice.header.length) {
    doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
    currentHeight += pdfConfig.subLineHeight;
    //}
    
    currentHeight += pdfConfig.subLineHeight;
    doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
    return doc;
}

export default Carpeta;
