import React, { useCallback, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { CardLike, HeaderLike, ModalLike, HeaderInlineLike } from './layout'
import ReactTooltip from 'react-tooltip'
import { useState } from 'react'

const Form = ({ type = "card", reset, dispatch: outerDispatch = () => { }, data = {}, path = "", inline = false, ...rest }) => {
  const [init_data, setinitData] = useState(JSON.parse(JSON.stringify(data)) || {});
  const modified = !reset && !isEqual(init_data, data);
  //const [submit, setSubmit] = useState(() => () => { console.warn("No submit button defined. Check form configuration.") })
  const form_path = path + ".data"

  useEffect(() => {
    if (reset && !modified) {
      setinitData(JSON.parse(JSON.stringify(data)) || {});
    } 
  }, [modified, reset, outerDispatch, data])

  const dispatch = useCallback((action) => {
    //console.log("form dispatch", `${form_path}.${action.path}`, action.payload)
    if (reset) outerDispatch({ type: "set-data", payload: false, path: `${path}.reset` });
    outerDispatch({ type: "set-data", payload: action.payload, path: `${form_path}.${action.path}` });
  }, [reset, path, form_path, outerDispatch])

  const formRef = useRef(null);

  const submitHandler = useCallback( e => {
    e.preventDefault();
    console.log("submited form");
  }, [])

  const props = { formRef, form_path, modified, form_data: data, dispatch/*, setSubmit*/, ...rest }

  return (
    <form ref={formRef} className={inline ? "form-inline" : undefined}  onSubmit={submitHandler} >
      {type === "modal" && <ModalLike {...props} />}
      {type === "card" && <CardLike {...props} />}
      {type === "header" && (inline ? <HeaderInlineLike {...props} /> : <HeaderLike {...props} />)}
      <ReactTooltip effect="solid" place="top" multiline className="py-1 px-2" />
    </form>
  )
}

export default Form;
