export const testPage2 = {
  "name": "testpage2",
  "type": "empleado",
  "title": "Segundas Pruebas",
  "orden": "20",
  "data": JSON.stringify({
    print: {
      plantilla: "canje",
      archivo: "comprobante.pdf",
      action: "print",
      data: [{
        empresa: "Propagas",
        sucursal: "Cometa - Herrera",
        documento: "Comprobante de Canje",
        fecha: "12/10/2021 5:27 PM",
        nombre: "Carlos Perez Baquero",
        cedula: "001-0818265-0",
        monto: "500.00",
        id: "389595825",
        usuario: "Omar A. Pena"
      }]
    }
  }),
  "form": [
    {
      "name": "test",
      "entity": "test",
      "type": "header",
      "button": [
        {
          "label": "Boton 1",
          "style": "outline-primary",
          "action": "gotoprint"
        },
        {
          "label": "Boton 2",
          "style": "outline-primary",
          "action": "quittimer",
        }
      ]
    }
  ],
  "action": [
    {
      "name": "testSet",
      "type": "set",
      "set": [
        {
          "cookie": "testCookie",
          "exp": "\"@global.header.data.texto\""
        }
      ]
    },
    {
      "name": "gotoprint",
      "type": "goto",
      "page": "\"print\"",
      //"params": "{}"
    },
    {
      "name": "testGet",
      "type": "set",
      "set": [
        {
          "path": "cookieValue",
          "exp": "\"@cookie.testCookie\""
        }
      ]
    },
    {
      "name": "logthis",
      "type": "log",
      "info": "\"@local\"",
      /*"condition": [
          {
              "type": "exists",
              "a": "\"@global.resultado.error.0.message\""
          }
      ]*/
    },
    {
      name: "testroutine",
      type: "routine",
      iteration: "2",
      inner: [
        {
          "type": "timer",
          "seconds": 3,
          "actions": "alerta",
          "label": "\"timer_@local.index\""
        },
      ]
    },
    {
      name: "testtimer",
      "type": "timer",
      "seconds": 5,
      "actions": "alerta",
      "label": "\"testtimer\""
    },
    {
      name: "quittimer",
      "type": "timer",
      "label": "\"testtimer\""
    },
    {
      "name": "alerta",
      "type": "event",
      "as": "\"error\"",
      "message": "\"Leandro palomo\"",
      "timeout": "5"
      /*"condition": [
          {
              "type": "exists",
              "a": "\"@global.resultado.error.0.message\""
          }
      ]*/
    }
  ],
  "entity": [
    {
      "name": "test",
      "field": [
        {
          "name": "s1",
          "label": "Info",
          "type": "section",
          "modes": "both",
          "initmode": "read",
          "readmd": `#### Hola, *@local.texto;* !`
        },
        {
          "name": "texto",
          "type": "text",
          "label": "Texto",
          "size": "lg"
        },
        {
          "name": "elige",
          "type": "select",
          "label": "Elige una opcion",
          "size": "lg",
          "options": '["uno","dos","tres","cuatro","cinco","seis","siete","ocho","nueve","diez","once","doce","trece","catorce","quince","diez y seis","diez y siete","diez y ocho","diez y nueve", "veinte"]',
          "select_multi": "true",
          "select_custom": "true"
        }

        //,
        //{
        //    "name": "unjson",
        //    "type": "json",
        //    "label": "Algun JSON",
        //    "size": "lg"
        //},
        //{
        //    "name": "otrojson",
        //    "type": "json",
        //    "json": "true",
        //    "label": "Otro JSON",
        //    "size": "xl"
        //}

      ]
    },
    {
      "name": "testdata",
      "field": [
        {
          "name": "nombre",
          "type": "text",
          "label": "Nombre",
          "size": "md"
        },
        {
          "name": "accion",
          "type": "button",
          "label": "Botones",
          "style": "btn-outline-default",
          "size": "xs"
        }
      ]
    }
  ]
}

export default testPage2;
